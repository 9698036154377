/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.navbar {
  background-color: rgb(83, 130, 187);
  display: flex;
  height: 40px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0;

}

.navbar>li {
  list-style-type: none;
  text-decoration: none;
  text-align: center;*/
  /* padding: 20px; */
  /* margin: 0 60px 0 60px; *//*
  margin-left: 3%;
}

a {
  text-decoration: none;
  text-align: center;
}

*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  -o-appearance: textfield;
  appearance: textfield;
}

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}

/* NIZAR CODE */
/* mobile-menu mean-container */

.display-none {
  display: none !important;
}

.font-18px {
  font-size: 18px;
}

#offCanvasModal .canvas__menu > div > div:nth-child(2) {
  display: none;
}
/* mobile-menu mean-container */