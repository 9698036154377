/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");
/* latin */
@font-face {
  font-family: "Monoment Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Monoment Regular"), url("../fonts/monumentextended-regular-webfont.woff2") format("woff2");
}
:root {
  /**
  @color declaration
  */
  --clr-common-white: #fff;
  --clr-common-black: #222;
  --clr-common-black-2: #444444;
  --clr-common-black-3: #333333;
  --clr-common-gray: #777;
  --clr-common-body-text-color: #899399;
  --clr-common-orange: #ffae00;
  --clr-common-hr-border-color: #eceff8;
  --clr-common-transparent: transparent;
  --clr-heading-primary: #222222;
  --clr-soft-gray: #e9e4d6;
  --clr-soft-gray-2: #666666;
  --clr-soft-gray-3: #999999;
  --clr-soft-gray-4: #f4f4f4;
  --clr-soft-gray-5: #e1e1e1;
  --clr-soft-gray-6: #f7f7f7;
  --clr-soft-gray-7: #555555;
  --clr-soft-gray-8: #cbcbcb;
  --clr-soft-gray-9: #dee6ea;
  --clr-soft-gray-10: #e8e8e8;
  --clr-soft-gray-11: #888888;
  --clr-soft-gray-12: #f9f9f9;
  --clr-soft-white: #dfdbce;
  --clr-soft-white-2: #eaeaea;
  --clr-soft-black-1: #272727;
  --clr-theme-1: #f57e60;
  --clr-theme-1-sub: #7da468;
  --clr-theme-2: #f6edea;
  --clr-theme-sub-2: #d4a533;
  --clr-theme-sub-2-2: #f6f2ea;
  --clr-theme-sub-2-3: #eaf2f6;
  --clr-theme-3: #f67174;
  --clr-theme-green: #77a464;
  --clr-border-1: #ebebeb;
  --clr-border-2: #f2f2f2;
  --clr-shadow-1: #d4a5334d;
  --fw-elight: 200;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-sbold: 600;
  --fw-bold: 700;
  --fw-ebold: 800;
  --fw-black: 900;
  --fz-: ;
  /**
  @font declaration
  */
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest-deal-area {
    padding-bottom: 70px;
  }
}

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

@font-face {
  font-family: "shutter_stonestandar";
  src: url(../fonts/shutter-stone-standar.ttf);
  font-weight: normal;
  font-style: normal;
}
/*
@font-face {
  font-family: "tuesdaynight";
  src: url(../fonts/TuesdayNight-Regular.otf);
  font-weight: 400;
  font-style: normal;
}
*/
.row {
  --bs-gutter-x: 30px;
  margin-right: calc(var(--bs-gutter-x) / -3);
  margin-left: calc(var(--bs-gutter-x) / -3);
}
.row.g-0, .row.gx-0 {
  --bs-gutter-x: 0;
}
.row.g-1, .row.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.row.g-2, .row.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.row:is(.row-2) {
  --bs-gutter-x: 20px;
}
.row:is(.row-3) {
  --bs-gutter-x: 4px;
}

body {
  font-family: "Archivo", sans-serif;
  font-weight: var(--fw-regular);
  font-style: normal;
  color: var(-clr-common-body-text-color);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  body {
    overflow-x: hidden;
  }
}

.container {
  max-width: 1430px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .container {
    max-width: 1220px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 990px;
  }
}
.container:is(.container-2) {
  max-width: 1200px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .container:is(.container-2) {
    max-width: 1100px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container:is(.container-2) {
    max-width: 895px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container:is(.container-2) {
    max-width: 795px;
  }
}

.img, img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

ul, li {
  list-style: none;
}

a {
  text-decoration: none;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.fix {
  overflow: hidden;
}

a,
button,
.button,
i,
span,
input,
textarea,
*::after,
*::before {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

button {
  outline: 0;
  border: 0;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: medium none;
}

button:focus, input:focus, input:focus, textarea, textarea:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Archivo", sans-serif;
  color: var(--clr-heading-heading-color);
  margin-top: 0px;
  font-weight: var(--fw-sbold);
  text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 40px;
  font-weight: var(--fw-medium);
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  color: var(--clr-common-body-text-color);
  margin-bottom: 15px;
}

hr {
  border-bottom: 1px solid var(--clr-common-hr-border-color);
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  color: var(--clr-soft-soft-black-soft);
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--fw-regular);
}

*::-moz-selection {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
  text-shadow: none;
}

::selection {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
  text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
input::-webkit-input-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
}
input:-moz-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
}
input::-moz-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

/*--
    - Slider height
-----------------------------------------*/
.slider-height {
  min-height: 1080px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-height {
    min-height: 690px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .slider-height {
    min-height: 592px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-height {
    min-height: 475px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height {
    min-height: 380px;
  }
}
@media (max-width: 575px) {
  .slider-height {
    min-height: 145px;
  }
}

/*--
    - Position
-----------------------------------------*/
.p-rel {
  position: relative;
}

.p-abs {
  position: absolute;
}

/*--
    - Section title
-----------------------------------------*/
.p-subtitle {
  color: var(--clr-theme-1);
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 9px;
  display: block;
  font-weight: var(--fw-sbold);
  text-transform: uppercase;
}
.p-subtitle:is(.p-subtitle-2) {
  color: var(--clr-theme-sub-2);
}
.p-subtitle-3 {
  color: var(--clr-theme-3);
}

.p-title {
  font-weight: var(--fw-bold);
  font-size: 30px;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .p-title {
    font-size: 22px;
    padding-bottom: 5px;
  }
}

.p-desc {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--clr-soft-gray-2);
}
@media (max-width: 575px) {
  .p-desc {
    font-size: 12px;
  }
}
.p-desc:is(.p-desc-2) {
  color: var(--clr-soft-gray-11);
}

.s2-title-sub {
  font-family: "tuesdaynight";
  font-size: 48px;
  color: #cecece;
  line-height: 24.02px;
  display: block;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .s2-title-sub {
    font-size: 42px;
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s2-title-sub {
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .s2-title-sub {
    margin-bottom: 25px;
    line-height: 1;
  }
}

.s2-title-main {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 15px;
}

.section-title-2 p {
  color: #666666;
  line-height: 30px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .section-title-2 p br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-title-2 p br {
    display: none;
  }
}

.section-title-s-p {
  padding: 0 73px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .section-title-s-p {
    padding: 0;
  }
}

.pb-47 {
  padding-bottom: 47px;
}

.pb-93 {
  padding-bottom: 93px;
}

.pb-28 {
  padding-bottom: 28px;
}

.mb-4-px {
  margin-bottom: 4px;
}

.pt-7 {
  padding-top: 7px;
}

.pb-245 {
  padding-bottom: 245px;
}

.mb-8 {
  margin-bottom: 8px;
}

.rating i {
  font-size: 14px;
  color: var(--clr-soft-gray-3);
  font-weight: 500;
}
.rating i.active {
  color: var(--clr-common-orange);
}

.black-bg {
  background-color: var(--clr-common-black) !important;
}

.black-bg-2 {
  background-color: #27272b;
}

.black-bg-3 {
  background-color: #252525;
}

.gray-bg {
  background-color: var(--clr-soft-gray-4);
}

.gray-bg-2 {
  background-color: var(--clr-soft-gray-6);
}

.gray-bg-3 {
  background-color: var(--clr-soft-gray-12);
}

.gray-bg-4 {
  background-color: #f6f6f6;
}

.gray-bg-5 {
  background-color: #fafafa;
}

.gray-bg-6 {
  background-color: #e9f1f5;
}

.theme-bg {
  background-color: var(--clr-theme-1);
}

.theme-bg-2 {
  background-color: var(--clr-theme-2);
}

.theme-bg-3 {
  background-color: var(--clr-theme-sub-2-2);
}

.theme-bg-4 {
  background-color: var(--clr-theme-sub-2-3);
}

.cream-bg {
  background-color: #f6f1e7;
}

.cream-bg-2 {
  background-color: #f6f2ea;
}

.light-green-bg {
  background-color: #f0f6ea;
}

.light-green-bg-2 {
  background-color: #d9edde;
}

.light-pink-bg {
  background-color: #f6edea;
}

.light-pink-bg-2 {
  background-color: #f3e6e5;
}

.wow {
  animation-duration: 2s;
  animation-fill-mode: backwards;
}

.border-bottom-gray {
  border-bottom: 1px solid var(--clr-soft-gray-5);
}

.white-text {
  color: var(--clr-common-white);
}

.center-text {
  text-align: center;
}

.mt-3-px {
  margin-top: 3px;
}

.row-5-px {
  --bs-gutter-x: 5px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-215 {
  padding-top: 215px;
}

.footer-border-top {
  border-top: 1px solid #333333;
}

.include-bg {
  background-size: cover;
  background-position: center center;
}

.hr-border {
  border-bottom: 1px solid #e5e5e5;
}

.box-plr-45 {
  padding-left: 45px;
  padding-right: 45px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .box-plr-45 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-plr-45 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .box-plr-45 {
    padding-left: 0;
    padding-right: 0;
  }
}

/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

/*-- Overlay Color --*/
[data-overlay=light]::before {
  background-color: var(--clr-common-white);
}

[data-overlay=dark]::before {
  background-color: var(--clr-common-black);
}

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1;
}

[data-opacity="2"]::before {
  opacity: 0.2;
}

[data-opacity="3"]::before {
  opacity: 0.3;
}

[data-opacity="4"]::before {
  opacity: 0.4;
}

[data-opacity="5"]::before {
  opacity: 0.5;
}

[data-opacity="6"]::before {
  opacity: 0.6;
}

[data-opacity="7"]::before {
  opacity: 0.7;
}

[data-opacity="8"]::before {
  opacity: 0.8;
}

[data-opacity="9"]::before {
  opacity: 0.9;
}

.tag {
  font-size: 14px;
  text-decoration: none;
  padding-bottom: 11px;
  display: inline-block;
}
.tag:is(.tag-4) {
  color: #d5a734;
}

.header-main ul li {
  display: inline-block;
  list-style: none;
  margin-right: 33px;
  position: relative;
}
.header-main ul li > a {
  font-size: 13px;
  color: var(--clr-common-white);
  font-weight: var(--fw-sbold);
  text-transform: uppercase;
  line-height: 1;
  display: block;
  padding: 56px 0;
  text-decoration: none;
}
.header-main ul li:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.header-main ul li:hover > .sub-menu .sub-menu {
  top: 0;
  left: 100%;
}
.header-main ul li:hover a {
  color: var(--clr-theme-1);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header-main ul li {
    margin-right: 19px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .header-main ul li {
    margin-right: 21px;
  }
}
.header-main:is(.header-main-1) ul li a {
  color: var(--clr-common-white);
}
.header-main:is(.header-main-1) ul li a::before {
  background-color: var(--clr-theme-1);
}
.header-main:is(.header-main-1) ul li:hover > a {
  color: var(--clr-theme-1);
}
.header-main:is(.header-main-1) ul li:hover > a::before {
  width: 100%;
}
.header-main:is(.header-main-1) ul li .sub-menu {
  border-color: var(--clr-common-white);
}
.header-main:is(.header-main-2) ul li a {
  color: var(--clr-common-black);
}
.header-main:is(.header-main-2) ul li a::before {
  background-color: var(--clr-theme-sub-2);
}
.header-main:is(.header-main-2) ul li:hover > a {
  color: var(--clr-theme-sub-2);
}
.header-main:is(.header-main-2) ul li:hover > a::before {
  width: 100%;
}
.header-main:is(.header-main-2) ul li .sub-menu {
  border-color: var(--clr-common-black);
}
.header-main:is(.header-main-2) ul li {
  margin: 0 16px;
}
.header-main:is(.header-main-2) ul li > a {
  padding: 38px 0;
  display: inline-block;
}
.header-main:is(.header-main-2) .sub-menu li {
  padding-left: 0;
}
.header-main:is(.header-main-3) ul li a {
  color: var(--clr-common-black);
}
.header-main:is(.header-main-3) ul li a::before {
  background-color: var(--clr-theme-1);
}
.header-main:is(.header-main-3) ul li:hover > a {
  color: var(--clr-theme-1);
}
.header-main:is(.header-main-3) ul li:hover > a::before {
  width: 100%;
}
.header-main:is(.header-main-3) ul li .sub-menu {
  border-color: var(--clr-common-black);
}
.header-main:is(.header-main-3) ul li > a {
  padding: 50px 0;
}
.header-main:is(.header-main-4) ul li a {
  color: var(--clr-common-black);
}
.header-main:is(.header-main-4) ul li a::before {
  background-color: var(--clr-common-black);
}
.header-main:is(.header-main-4) ul li:hover > a {
  color: var(--clr-common-black);
}
.header-main:is(.header-main-4) ul li:hover > a::before {
  width: 100%;
}
.header-main:is(.header-main-4) ul li .sub-menu {
  border-color: var(--clr-common-black);
}
.header-right a {
  font-size: 14px;
  color: var(--clr-heading-primary);
  text-decoration: none;
}
.header-icon :is(i) {
  font-size: 18px;
  color: var(--clr-heading-primary);
}
.header-icon a {
  position: relative;
}
.header-icon a:not(:last-child) {
  margin-right: 15px;
}
.header-icon a :is(span) {
  font-size: 11px;
  color: var(--clr-heading-primary);
  font-weight: var(--fw-regular);
  position: absolute;
  left: calc(100% + 3px);
  top: -5px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-icon-2 {
    margin-left: 0;
  }
}
.header-icon-3 button {
  background: #eae1de !important;
}
.header-icon-3 button span {
  background: #d4a533 !important;
  color: #fff;
}
.header-icon-4 button {
  background: #eaeaea !important;
}
.header-icon-4 button span {
  background: #d5a734 !important;
  color: #fff;
}

.header-right :is(button) {
  border: 0;
  background: transparent;
  font-weight: var(--fw-fweight-medium);
  font-size: 18px;
  background: var(--clr-soft-gray);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: var(--clr-heading-heading-color);
  margin-left: 12px;
  display: inline-block;
  position: relative;
}
.header-right :is(button) :is(span) {
  font-size: 11px;
  color: var(--clr-common-white);
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  background: var(--clr-theme-1);
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  top: 0;
}
.header-right :is(button):is(.cart2) {
  background-color: var(--clr-soft-gray-9);
}
.header-right :is(button):is(.cart2) :is(span) {
  background-color: var(--clr-theme-sub-2);
}
.header-right :is(button):is(.cart4) {
  background-color: var(--clr-soft-white-2);
}
.header-right :is(button):is(.cart4) :is(span) {
  background-color: var(--clr-theme-sub-2);
}
.header-right:is(.header-right-2) button span {
  background-color: var(--clr-theme-sub-2);
}
.header-right:is(.header-right-2) a:hover {
  color: var(--clr-theme-sub-2);
}
.header-right:is(.header-right-5) button {
  background-color: #f2e1e1;
}
.header-right:is(.header-right-5) button span {
  background-color: #f67174;
}
.header-right:is(.header-right-5) a:hover {
  color: #f67174;
}

.sub-menu.left-text {
  text-align: left;
}

.basic-pagination ul li {
  display: inline-block;
}
.basic-pagination ul li:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 575px) {
  .basic-pagination ul li {
    margin-bottom: 10px;
  }
}
.basic-pagination ul li a {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 42px;
  text-align: center;
  border: 2px solid var(--clr-border-1);
  font-size: 12px;
  font-weight: 500;
}
.basic-pagination ul li a:hover, .basic-pagination ul li a.active {
  color: var(--clr-common-black);
  border-color: var(--clr-common-black);
}

:is(.cart-btn) a {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  text-decoration: none;
  display: block;
  font-weight: var(--fw-sbold);
  text-align: center;
  padding: 18px 0;
}
:is(.cart-btn):is(.p-abs) {
  left: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  right: 0;
  bottom: -60px;
}
:is(.cart-btn):is(.cart-btn-1) {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn-1):hover {
  background-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn-2) {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn-2):hover {
  background-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn3) {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn3):hover {
  background-color: var(--clr-theme-3);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn-4) {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
}
:is(.cart-btn):is(.cart-btn-4):hover {
  background-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
}

:is(.discount) {
  line-height: 1;
  font-size: 13px;
  padding: 10px 15px;
  display: inline-block;
  border-radius: 2px;
}
:is(.discount):is(.discount-1) {
  color: var(--clr-common-white);
  background: var(--clr-theme-1);
}
:is(.discount):is(.discount-2) {
  color: var(--clr-common-white);
  background: var(--clr-theme-1-sub);
}
:is(.discount):is(.discount-3) {
  color: var(--clr-common-white);
  background: var(--clr-theme-sub-2);
}
:is(.discount):is(.discount-4) {
  color: var(--clr-common-white);
  background: var(--clr-theme-3);
}
:is(.discount):is(.p-abs) {
  position: absolute;
  top: 20px;
  left: 20px;
}
:is(.discount):is(.discount-6) {
  background: #d4a533;
}
@media (max-width: 575px) {
  :is(.discount) {
    padding: 7px 11px;
  }
}

.p-btn a, .p-btn button {
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  font-weight: 600;
  border: 2px solid transparent;
  display: inline-block;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  padding: 16px 75px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .p-btn a, .p-btn button {
    padding: 13px 55px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .p-btn a, .p-btn button {
    padding: 14px 60px;
  }
}
@media (max-width: 575px) {
  .p-btn a, .p-btn button {
    padding: 10px 40px;
  }
}
.p-btn:is(.p-btn-1) a {
  border-color: var(--clr-common-black);
  color: var(--clr-common-black);
  background-color: var(--clr-common-transparent);
}
.p-btn:is(.p-btn-1) a:hover {
  border-color: var(--clr-common-black);
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
}
.p-btn:is(.p-btn-2) a {
  padding: 16px 47px;
  border-color: var(--clr-common-transparent);
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
}
.p-btn:is(.p-btn-2) a:hover {
  border-color: var(--clr-common-black);
  color: var(--clr-common-black);
  background-color: var(--clr-common-white);
}
@media (min-width: 768px) and (max-width: 991px) {
  .p-btn:is(.p-btn-2) a {
    padding: 9px 29px;
  }
}
@media (max-width: 575px) {
  .p-btn:is(.p-btn-2) a {
    padding: 5px 13px;
  }
}
.p-btn:is(.p-btn-3) a {
  padding: 16px 52px;
  border-color: var(--clr-border-1);
  color: var(--clr-common-black);
  background-color: var(--clr-common-transparent);
}
.p-btn:is(.p-btn-3) a:hover {
  border-color: var(--clr-common-transparent);
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
}
@media (min-width: 768px) and (max-width: 991px) {
  .p-btn:is(.p-btn-3) a {
    padding: 10px 40px;
  }
}
@media (max-width: 575px) {
  .p-btn:is(.p-btn-3) a {
    padding: 8px 41px;
  }
}
.p-btn:is(.p-btn-4) a, .p-btn:is(.p-btn-4) button {
  padding: 16px 47px;
  border-color: var(--clr-common-transparent);
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
}
.p-btn:is(.p-btn-4) a:hover, .p-btn:is(.p-btn-4) button:hover {
  border-color: var(--clr-common-black);
  color: var(--clr-common-black);
  background-color: var(--clr-common-white);
}
.p-btn:is(.p-btn-5) a {
  padding: 16px 88px;
  border-color: var(--clr-common-transparent);
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
}
.p-btn:is(.p-btn-5) a:hover {
  border-color: var(--clr-common-transparent);
  color: var(--clr-common-white);
  background-color: var(--clr-theme-sub-2);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .p-btn:is(.p-btn-5) a {
    padding: 12px 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .p-btn:is(.p-btn-5) a {
    padding: 9px 29px;
  }
}
@media (max-width: 575px) {
  .p-btn:is(.p-btn-5) a {
    padding: 5px 13px;
  }
}

:is(.subscribe-btn) {
  background: #222222;
  color: #fff;
  border: 0;
  border: 2px solid transparent;
  padding: 18px 102px;
  line-height: 1;
  font-weight: 600;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  text-transform: uppercase;
}
:is(.subscribe-btn):hover {
  background-color: transparent;
  color: #222;
  border-color: #222;
}
:is(.subscribe-btn):is(.subscribe-btn-1) {
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
  color: var(--clr-common-white);
  border-color: var(--clr-common-black);
}
:is(.subscribe-btn):is(.subscribe-btn-1):hover {
  color: var(--clr-common-black);
  background-color: var(--clr-common-white);
  border-color: var(--clr-common-black);
}
:is(.subscribe-btn):is(.subscribe-btn-2) {
  color: var(--clr-common-white);
  background-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
  border-color: var(--clr-theme-sub-2);
}
:is(.subscribe-btn):is(.subscribe-btn-2):hover {
  color: var(--clr-theme-sub-2);
  background-color: var(--clr-common-transparent);
  border-color: var(--clr-theme-sub-2);
}
@media (max-width: 575px) {
  :is(.subscribe-btn) {
    padding: 11px 55px;
  }
}
:is(.subscribe-btn).subscribe-btn-3 {
  background-color: var(--clr-theme-3);
}
:is(.subscribe-btn).subscribe-btn-3:hover {
  background-color: var(--clr-common-white);
  color: var(--clr-theme-1);
}

.s-btn {
  display: inline-block;
  height: 50px;
  line-height: 48px;
  text-align: center;
  padding: 0 30px;
  font-size: 13px;
  font-weight: 500;
  color: var(--clr-common-black);
  border: 1px solid var(--clr-common-black);
  text-transform: uppercase;
}
.s-btn:hover {
  color: var(--clr-common-white);
  background: var(--clr-common-black);
}
.s-btn-2 {
  color: var(--clr-common-white);
  background: var(--clr-common-black);
}
.s-btn-2:hover {
  background: var(--clr-common-white);
  color: var(--clr-common-black);
}
.s-btn-3 {
  /*padding: 0 85px;*/
  color: #fff;
  background: #d4a533;
  border-color: #d4a533;
}
.s-btn-3:hover {
  color: var(--clr-common-black);
  background: #fff;
  border-color: #cccccc;
}
.s-btn-4 {
  background: #f6f6f6;
  color: #222;
  border-color: #e1e1e1;
  text-transform: capitalize;
  padding: 0 54px;
}
.s-btn-5 {
  height: 40px;
  line-height: 38px;
  padding: 0 30px;
  background: #222;
  color: #fff;
  text-transform: capitalize;
}
.s-btn-5:hover {
  background: #fff;
  color: #222;
}
.s-btn-big {
  height: 55px;
  line-height: 53px;
  padding: 0 155px;
}
.s-btn-big-2 {
  padding: 0 95px;
}
@media (max-width: 575px) {
  .s-btn-big-2 {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.product-thumb {
  overflow: hidden;
  position: relative;
}
.product-thumb :is(img:nth-child(2)) {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 575px) {
  .product-thumb .countdown {
    display: none;
  }
}

.product-action:is(.p-abs) {
  right: -60px;
  top: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

:is(.icon-box) {
  display: block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  border: 1px solid transparent;
  text-align: center;
  margin-bottom: 5px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
:is(.icon-box):is(.icon-box-1) {
  border-color: var(--clr-border-1);
  color: var(--clr-common-black);
  background: var(--clr-common-white);
}
:is(.icon-box):is(.icon-box-1):hover {
  border-color: var(--clr-theme-1);
  color: var(--clr-common-white);
  background-color: var(--clr-theme-1);
}
:is(.icon-box):is(.icon-box-2) {
  border-color: var(--clr-border-1);
  color: var(--clr-common-black);
  background: var(--clr-common-white);
}
:is(.icon-box):is(.icon-box-2):hover {
  border-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
  background-color: var(--clr-theme-sub-2);
}
:is(.icon-box):is(.icon-box-3) {
  border-color: var(--clr-border-1);
  color: var(--clr-common-black);
  background: var(--clr-common-white);
}
:is(.icon-box):is(.icon-box-3):hover {
  border-color: var(--clr-theme-1);
  color: var(--clr-common-white);
  background-color: var(--clr-theme-1);
}
:is(.icon-box):is(.icon-box-3):hover {
  border-color: var(--clr-border-1);
  color: var(--clr-common-black);
  background: var(--clr-common-white);
}
:is(.icon-box):is(.icon-box-3):hover:hover {
  border-color: var(--clr-theme-3);
  color: var(--clr-common-white);
  background-color: var(--clr-theme-3);
}
:is(.icon-box):is(.icon-box-4) {
  border-color: var(--clr-border-1);
  color: var(--clr-common-black);
  background: var(--clr-common-white);
}
:is(.icon-box):is(.icon-box-4):hover {
  border-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
  background-color: var(--clr-theme-sub-2);
}
:is(.icon-box) i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  transform: translate(-50%, -50%);
}
:is(.icon-box) i:first-child {
  top: -50%;
}
:is(.icon-box):hover i:first-child {
  top: 50%;
}
:is(.icon-box):hover i:last-child {
  top: 150%;
}

.pro-title {
  font-size: 14px;
  font-weight: var(--fw-regular);
  color: var(--clr-common-black);
  margin-bottom: 0px;
  padding-bottom: 6px;
}
.pro-title a {
  text-decoration: none;
}
.pro-title:is(.pro-title-1):hover {
  color: var(--clr-theme-1);
}
.pro-title:is(.pro-title-2):hover {
  color: var(--clr-theme-sub-2);
}
.pro-title:is(.pro-title-4) {
  font-weight: var(--fw-bold);
  color: var(--clr-common-black);
  font-size: 18px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: 0;
  padding-bottom: 22px;
}
.pro-title:is(.pro-title-4):hover {
  color: var(--clr-theme-sub-2);
}
.pro-title:is(.pro-title-5):hover {
  color: var(--clr-theme-3);
}

.pro-price {
  margin-bottom: 1px;
  font-size: 14px;
  color: var(--clr-soft-gray-2);
}

.product-content {
  padding: 28px 0;
}

.countdown {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px 43px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.countdown.countdown-2 {
  padding-left: 23px;
  padding-right: 23px;
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .countdown.countdown-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.countdown .countdown-inner {
  background-color: var(--clr-common-white);
  padding: 9px 0;
  display: block;
  padding-bottom: 7px;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.countdown .countdown-inner ul li {
  list-style: none;
  font-size: 12px;
  color: var(--clr-soft-black-1);
  display: inline-block;
  padding: 0 12px;
  position: relative;
  text-transform: uppercase;
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .countdown .countdown-inner ul li {
    padding: 0 0px;
  }
}
.countdown .countdown-inner ul li span {
  display: block;
  line-height: 1;
}
.countdown .countdown-inner ul li:not(:last-child)::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  content: "";
  background-color: #ebebeb;
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .countdown .countdown-inner ul li:not(:last-child)::after {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .countdown {
    padding: 30px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .countdown {
    padding: 30px 22px;
  }
}

.single-product:hover :is(img:nth-child(2)) {
  opacity: 1;
  visibility: visible;
  transform: scale(1.1);
}
.single-product:hover :is(.cart-btn.p-abs) {
  bottom: 0;
}
.single-product:hover :is(.countdown) {
  opacity: 0;
  visibility: hidden;
  bottom: -60px;
}
.single-product:hover :is(.product-action) {
  right: 20px;
}

.single-blog p {
  margin-bottom: 0;
  padding-bottom: 25px;
  color: var(--clr-soft-gray-2);
}
.single-blog-6 {
  padding-bottom: 280px;
}
@media (max-width: 575px) {
  .single-blog-6 {
    padding-bottom: 30px;
  }
}
.blog-content {
  padding: 40px 0;
  padding-right: 3px;
}
.blog-content:is(.blog-content-2) {
  padding: 25px 0;
}
.blog-content:is(.blog-content-6) {
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  background-color: #fff;
  padding: 30px 30px 8px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-content:is(.blog-content-6) {
    bottom: 60px;
  }
}
@media (max-width: 575px) {
  .blog-content:is(.blog-content-6) {
    position: static;
  }
}

.blog-thumb {
  overflow: hidden;
}
.blog-thumb img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  max-width: 100%;
}

.tag-btn {
  font-size: 12px;
  font-weight: var(--fw-sbold);
  border: 1px solid transparent;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  padding: 10px 18px;
  border-radius: 2px;
  text-transform: uppercase;
  color: var(--clr-theme-1);
  border-color: var(--clr-theme-1);
  background-color: var(--clr-common-transparent);
}
.tag-btn:hover {
  background-color: var(--clr-theme-1);
  color: var(--clr-common-white);
}
@media (min-width: 768px) and (max-width: 991px) {
  .tag-btn {
    padding: 6px 12px;
  }
}
.tag-btn:is(.tag-btn-2) {
  color: var(--clr-theme-sub-2);
  border-color: var(--clr-theme-sub-2);
  background-color: var(--clr-common-transparent);
  background-color: var(--clr-common-white);
}
.tag-btn:is(.tag-btn-2):hover {
  background-color: var(--clr-theme-sub-2);
  color: var(--clr-common-white);
}
.tag-btn.tag-btn-6 {
  background-color: #d4a533;
  color: #fff;
  text-transform: uppercase;
}
.tag-btn.tag-btn-6:hover {
  background-color: var(--clr-common-black);
  border-color: var(--clr-common-black);
}

.blog-meta {
  font-size: 14px;
  line-height: 3.0715;
  color: var(--clr-soft-gray-3);
  padding-bottom: 6px;
}
.blog-meta a {
  color: var(--clr-common-black);
  text-decoration: none;
}
.blog-meta a:hover {
  color: var(--clr-theme-1);
}
.blog-meta:is(.blog-meta-2) a:hover {
  color: var(--clr-theme-sub-2);
}
.blog-meta:is(.blog-meta-5) a:hover {
  color: var(--clr-theme-3);
}

.blog-title {
  font-size: 18px;
  font-weight: var(--fw-bold);
  line-height: 1.333;
  margin-bottom: 0;
  padding-bottom: 21px;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}
.blog-title a {
  text-decoration: none;
}
.blog-title:is(.blog-title-1):hover {
  color: var(--clr-theme-1);
}
.blog-title:is(.blog-title-2):hover {
  color: var(--clr-theme-sub-2);
}
.blog-title:is(.blog-title-5):hover {
  color: var(--clr-theme-3);
}
.blog-title:is(.blog-title-6):hover {
  color: inherit;
}
.blog-title:is(.blog-title-6) a:hover {
  color: #d4a533;
}

.single-blog:hover img {
  transform: scale(1.1);
}

.instagram-gallery ul {
  display: flex;
}
.instagram-gallery ul li {
  position: relative;
  list-style: none;
}
.instagram-gallery ul li :is(.action) {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
.instagram-gallery ul li :is(.action)::before {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--clr-common-black);
  content: "";
}
.instagram-gallery ul li :is(.action) i {
  opacity: 0;
  visibility: hidden;
  color: var(--clr-common-white);
  position: relative;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-size: 24px;
}
.instagram-gallery ul li:hover :is(.action)::before {
  opacity: 0.5;
  visibility: visible;
}
.instagram-gallery ul li:hover :is(.action) i {
  opacity: 1;
  visibility: visible;
}

.has-underline {
  font-size: 16px;
  position: relative;
  font-weight: var(--fw-sbold);
  padding-bottom: 4px;
  text-decoration: none;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: var(--clr-common-black);
}
.has-underline::after {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 2px;
  background-color: var(--clr-common-black);
  content: "";
}
.has-underline:is(.has-underline-sm)::after {
  width: 30px;
}
.has-underline:hover {
  color: var(--clr-theme-sub-2);
}
.has-underline:hover:after {
  background-color: var(--clr-theme-sub-2);
}
.has-underline:is(.has-underline-2):hover {
  color: var(--clr-theme-1);
}
.has-underline:is(.has-underline-2):hover::after {
  background-color: var(--clr-theme-1);
}

.single-service {
  padding: 0 19px;
}
.single-service img {
  padding-bottom: 28px;
}
.single-service h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: var(--fw-medium);
  padding-bottom: 22px;
  color: var(--clr-common-black);
}
.single-service h5 a {
  text-decoration: none;
}
.single-service h5:hover {
  color: var(--clr-theme-sub-2);
}
.single-service p {
  font-size: 18px;
  color: var(--clr-soft-gray-2);
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .single-service p {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-service p {
    font-size: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-service {
    padding: 0 3px;
  }
}

.pro-content-4 {
  padding: 24px 0;
}

.product-link {
  font-size: 16px;
  font-weight: var(--fw-sbold);
  display: inline-block;
  text-decoration: none;
  color: var(--clr-common-black);
  position: relative;
}
.product-link::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  height: 2px;
  background-color: var(--clr-common-black);
  content: "";
}
.product-link:is(.w-50-border)::after {
  width: 50%;
}
.product-link:is(.product-link-4):hover {
  color: var(--clr-theme-sub-2);
}
.product-link:is(.product-link-4):hover::after {
  background-color: var(--clr-theme-sub-2);
}

.pro-price del {
  margin-left: 5px;
}

.owl-dots {
  display: flex;
  justify-content: center;
}
.owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  background: var(--clr-soft-gray-10);
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}
.owl-dots .owl-dot.active span {
  width: 10px;
  height: 10px;
  border: 1px solid var(--clr-common-black);
  background-color: transparent;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}

.lookbook-slider .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}
.lookbook-slider .owl-dots .owl-dot span {
  background-color: rgba(255, 255, 255, 0.2);
  border-width: 2px;
  border-color: #fff;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-area {
    padding-bottom: 75px;
  }
}
@media (max-width: 575px) {
  .banner-area {
    padding-bottom: 45px;
  }
}

.single-banner {
  position: relative;
  padding: 120px 85px;
  padding-top: 117px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .single-banner {
    padding-left: 30px;
  }
}
.single-banner::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  display: none;
}
@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner::after {
    display: block;
  }
}
.single-banner .thumb img {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-content {
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-content {
    width: 53%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content {
    width: 50%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content {
    width: 56%;
  }
}
.banner-content span {
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  padding-bottom: 19px;
  font-weight: var(--fw-sbold);
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner-content span {
    font-size: 13px;
    padding-bottom: 5px;
  }
}
@media (max-width: 575px) {
  .banner-content span {
    padding-bottom: 0;
    font-size: 13px;
  }
}
.banner-content:is(.banner-content-1) span {
  color: var(--clr-theme-1);
}
.banner-content:is(.banner-content-1) h4:hover a {
  color: var(--clr-theme-1);
}
.banner-content:is(.banner-content-2) span {
  color: var(--clr-theme-sub-2);
}
.banner-content:is(.banner-content-2) h4:hover a {
  color: var(--clr-theme-sub-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content:is(.banner-content-2) {
    width: 57%;
  }
}
.banner-content h4 {
  font-size: 24px;
  margin-bottom: 0px;
  padding-bottom: 16px;
  font-weight: var(--fw-sbold);
}
.banner-content h4 a {
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner-content h4 {
    padding-bottom: 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content h4 {
    padding-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .banner-content h4 {
    font-size: 19px;
    padding-bottom: 2px;
  }
}
.banner-content p {
  line-height: 1.868;
  color: var(--clr-common-black);
  margin-bottom: 0;
  font-size: 16px;
  padding-bottom: 50px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner-content p {
    padding-bottom: 15px;
  }
}
@media (max-width: 575px) {
  .banner-content p {
    line-height: 1.3;
    font-size: 15px;
    padding-bottom: 7px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .single-banner {
    padding: 40px;
    padding-top: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-banner {
    padding: 25px;
    padding-top: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-banner {
    padding: 25px;
    padding-top: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-banner {
    padding: 45px;
    padding-top: 45px;
  }
}
@media (max-width: 575px) {
  .single-banner {
    padding: 20px 10px;
    padding-top: 20px;
  }
}
.single-banner:is(.single-banner-2) {
  padding-left: 95px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .single-banner:is(.single-banner-2) {
    padding-left: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-banner:is(.single-banner-2) {
    padding-left: 39px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-banner:is(.single-banner-2) {
    padding-left: 16px;
  }
}
@media (max-width: 575px) {
  .single-banner:is(.single-banner-2) {
    padding: 40px 10px;
    padding-top: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-banner:is(.single-banner-2) {
    padding-left: 35px;
  }
}

.banner-content-3 span, .banner-content-3 h4, .banner-content-3 p {
  padding-bottom: 0;
}
.banner-content-3 span {
  margin-bottom: 7px;
}
.banner-content-3 h4 {
  margin-bottom: 10px;
}
.banner-content-3 p {
  margin-bottom: 15px;
}

.banner-item-6:hover .banner-thumb-6 {
  transform: scale(1.08);
}

.banner-title-6 {
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
}

.banner-content-6 {
  position: relative;
  z-index: 1;
  padding: 110px 0;
  width: 34%;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-content-6 {
    width: 47%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content-6 {
    width: 57%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-6 {
    width: 67%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-6 {
    width: 87%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content-6 {
    width: 58%;
  }
}
@media (max-width: 575px) {
  .banner-content-6 {
    width: 90%;
  }
}
.banner-content-6 .link-btn {
  font-size: 14px;
  color: #fff;
  position: relative;
}
.banner-content-6 .link-btn::after {
  position: absolute;
  content: "";
  bottom: -3px;
  left: auto;
  right: 0;
  width: 0;
  height: 2px;
  background: #fff;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.banner-content-6 .link-btn:hover::after {
  left: 0;
  right: auto;
  width: 100%;
}

.banner-thumb-6 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

@media (max-width: 575px) {
  .blog-area {
    padding-bottom: 0;
  }
}

.blog-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 114px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog-shape .shape-1 {
    display: none;
  }
}
.blog-shape .shape-2 {
  position: absolute;
  right: 0;
  bottom: 20px;
}
@media (max-width: 575px) {
  .blog-shape .shape-2 {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog-shape .shape-2 {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-shape .shape-2 {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-shape .shape-2 {
    display: none;
  }
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .blog-shape .shape-2 {
    display: none;
  }
}

.single-blog a {
  text-decoration: none;
}
.single-blog .pos-top-left {
  position: absolute;
  left: 20px;
  top: 20px;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-shape {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .postbox__wrapper {
    padding-right: 0;
  }
}
.postbox__title {
  font-size: 30px;
  padding-bottom: 0;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__title {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .postbox__title {
    font-size: 22px;
  }
}
.postbox__title a:hover {
  color: var(--clr-theme-1);
}
.postbox__thumb-slider .owl-nav button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 16px;
  color: #222;
  background: #fff;
  width: 40px;
  height: 60px;
  text-align: center;
  line-height: 60px;
}
@media (max-width: 575px) {
  .postbox__thumb-slider .owl-nav button {
    width: 20px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }
}
.postbox__thumb-slider .owl-nav button:hover {
  background: #222;
  color: #fff;
}
.postbox__thumb-slider .owl-nav button.nav-right {
  left: auto;
  right: 0;
}
.postbox__thumb-video {
  height: 625px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__thumb-video {
    height: 420px;
  }
}
@media (max-width: 575px) {
  .postbox__thumb-video {
    height: 320px;
  }
}
.postbox__thumb-video iframe {
  width: 100%;
  height: 100%;
}
.postbox__content {
  padding: 50px 0;
  border-top: 0;
  border: 1px solid #ebebeb;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__content {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__content {
    padding-left: 42px;
    padding-right: 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 575px) {
  .postbox__content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.postbox__content p {
  padding: 0 60px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__content p {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__content p {
    padding: 0 20px;
  }
}
@media (max-width: 575px) {
  .postbox__content p {
    padding: 0 5px;
  }
}
.postbox__tag {
  margin-bottom: 10px;
  text-transform: uppercase;
}
.postbox__tag-2 {
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .postbox__tag-3 {
    float: none;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.postbox__tag-3 a {
  color: #323232;
  font-size: 14px;
}
.postbox__tag-3 a:hover {
  color: #f57e60;
}
.postbox__meta {
  line-height: inherit;
  margin-bottom: 10px;
}
.postbox__meta-2 > span {
  font-size: 14px;
  color: #999999;
  margin-right: 55px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .postbox__meta-2 > span {
    margin-right: 40px;
  }
}
.postbox__meta-2 > span i {
  margin-right: 5px;
}
.postbox__meta-2 > span:hover {
  color: #222;
}
.postbox__meta-2-line {
  position: relative;
}
.postbox__meta-2-line::after {
  position: absolute;
  content: "";
  right: -25px;
  top: 43%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  background: #e1e1e1;
}
.postbox__btn a {
  background: #f6f6f6 !important;
}
.postbox__btn a:hover {
  background: #222 !important;
}
.postbox__details-title {
  font-size: 48px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__details-title {
    font-size: 35px;
  }
}
@media (max-width: 575px) {
  .postbox__details-title {
    font-size: 22px;
  }
}
.postbox__text p {
  font-size: 14px;
  color: #555555;
  line-height: 30px;
  margin-bottom: 30px;
}
.postbox__quote p {
  font-size: 18px;
  color: #222222;
}
@media (max-width: 575px) {
  .postbox__quote-icon {
    margin-bottom: 10px;
  }
}
.postbox__author {
  padding: 40px 30px;
  padding-right: 80px;
  background: #f7f7f7;
  border: 1px solid #e8e8e8;
}
@media (max-width: 575px) {
  .postbox__author {
    padding-right: 30px;
  }
}
.postbox__author-img {
  flex: 0 0 auto;
}
@media (max-width: 575px) {
  .postbox__author-img {
    margin-bottom: 20px;
    margin-right: 0;
  }
}
.postbox__author-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
@media (max-width: 575px) {
  .postbox__author-img img {
    width: 100%;
    height: auto;
  }
}
.postbox__author-content h5 {
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 50px;
  margin-bottom: 10px;
  position: relative;
}
.postbox__author-content h5::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 2px;
  background: #222;
}
.postbox__author-content p {
  margin-bottom: 20px;
}
.postbox__share {
  padding: 15px 0;
  padding-bottom: 20px;
}
.postbox__social span {
  color: #333333;
}
.postbox__social ul {
  display: inline-block;
  margin-left: 25px;
}
@media (max-width: 575px) {
  .postbox__social ul {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.postbox__social ul li {
  display: inline-block;
}
.postbox__social ul li a {
  color: #222;
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}
.postbox__social ul li a::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #eeeeee;
}
.postbox__social ul li a:hover {
  color: var(--clr-theme-1);
}
.postbox__social ul li:last-child a::after {
  display: none;
}
.postbox__line {
  height: 1px;
  border-bottom: 1px solid #ebebeb;
}
.postbox__related-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
}
.postbox__comment-title {
  font-size: 14px;
  text-transform: uppercase;
}
.postbox__comment-top p {
  color: #666666;
  margin-bottom: 40px;
}
.postbox__comment-input input, .postbox__comment-input textarea {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #ebebeb;
  margin-bottom: 30px;
}
.postbox__comment-input input::placeholder, .postbox__comment-input textarea::placeholder {
  color: #999999;
}
.postbox__comment-input input:focus, .postbox__comment-input textarea:focus {
  border-color: #f57e60;
}
.postbox__comment-input textarea {
  padding: 15px;
  height: 160px;
  resize: none;
}

.blog-grid .blog-content {
  padding: 30px 30px;
  border: 1px solid #ebebeb;
  border-top: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
  .blog-grid .blog-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.blog-grid .blog-content p {
  margin-bottom: 20px;
  padding-bottom: 0;
}
.blog-grid .blog-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 15px;
}
.blog-grid .blog-title:hover {
  color: inherit;
}
.blog-grid .blog-title a:hover {
  color: var(--clr-theme-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .blog__sidebar {
    margin-top: 50px;
  }
}

.sidebar__widget-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
}
.sidebar__widget-title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #222;
}
.sidebar__widget-padding {
  padding: 30px 30px;
  border: 1px solid #ebebeb;
}
.sidebar__widget-padding-2 {
  padding-bottom: 20px;
}
.sidebar__widget-padding-3 {
  border: 0;
  padding-bottom: 30px;
}
.sidebar__search {
  position: relative;
}
.sidebar__search input {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  padding-right: 30px;
  border: 1px solid #ebebeb;
}
.sidebar__search input::placeholder {
  color: #999999;
}
.sidebar__search input:focus {
  border-color: #222;
}
.sidebar__search button {
  background: transparent;
  font-size: 14px;
  color: #222;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  position: absolute;
}
.sidebar__author {
  padding: 30px 24px 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sidebar__author {
    padding-left: 0;
    padding-right: 0;
  }
}
.sidebar__author-thumb img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}
.sidebar__author-content h3 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.sidebar__author-content p {
  color: #666666;
}
.sidebar__links ul {
  line-height: 1;
}
.sidebar__links ul li {
  line-height: 1;
}
.sidebar__links ul li:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
.sidebar__links ul li a {
  color: #666666;
  font-size: 14px;
  padding: 10px 0 12px;
  display: inline-block;
}
.sidebar__links ul li a span {
  color: #bab9b9;
  margin-left: 8px;
}
.sidebar__links ul li a:hover {
  color: #222;
}
.sidebar__links ul li a:hover span {
  color: #222;
}
.sidebar__subscribe p {
  color: #222;
  margin-bottom: 13px;
}
.sidebar__subscribe input {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  margin-bottom: 10px;
  border: 1px solid #fff;
  background: #fff;
}
.sidebar__subscribe input:focus {
  border-color: #222;
}
.sidebar__subscribe input::placeholder {
  color: #999999;
}

.rc-post-item {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.rc-post-item-2 {
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 2px;
}
.rc-post-tag-2 {
  margin-bottom: 10px;
  font-size: 14px;
  color: #f57e60;
}
.rc-post-title {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.rc-post-title a:hover {
  color: var(--clr-theme-1);
}
.rc-post-title-2 {
  font-size: 16px;
  font-weight: 400;
}
.rc-post-title-2 a:hover {
  color: var(--clr-theme-1);
}
.rc-post-meta span {
  font-size: 14px;
  color: #999999;
}
.rc-post-meta-2 {
  margin-bottom: 10px;
}
.rc-post-meta-2 span {
  color: #999999;
  font-size: 14px;
}
.rc-post-content-2 {
  padding-top: 15px;
  padding-right: 10px;
}
.rc-post-thumb-slider .owl-nav button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 16px;
  color: #222;
  background: #fff;
  width: 30px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.rc-post-thumb-slider .owl-nav button:hover {
  background: #222;
  color: #fff;
}
.rc-post-thumb-slider .owl-nav button.nav-right {
  left: auto;
  right: 0;
}

.dot-style .slick-dots li button {
  border: 0;
  font-size: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.dot-style .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  background: transparent;
  border-color: var(--clr-common-black);
}
.dot-style:is(.dot-right) li {
  display: block;
}
.dot-style:is(.dot-right) .slick-dots {
  position: absolute;
  right: 60px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.dot-style:is(.dot-bottom-left) li {
  display: block;
}
.dot-style:is(.dot-bottom-left) .slick-dots {
  position: absolute;
  left: 260px;
  text-align: center;
  bottom: 60px;
}
.dot-style:is(.dot-bottom-left-2) ul {
  transform: rotate(90deg);
}
.dot-style:is(.dot-bottom-left-2) li {
  display: block;
}
.dot-style:is(.dot-bottom-left-2) .slick-dots {
  position: absolute;
  left: 260px;
  text-align: center;
  bottom: 60px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .dot-style:is(.dot-bottom-left-2) .slick-dots {
    left: 91px;
    bottom: 0;
  }
}
.dot-style:is(.dot-bottom-center) li {
  display: block;
}
.dot-style:is(.dot-bottom-center) .slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  text-align: center;
  bottom: -23px;
}

.default-bg {
  background-size: cover;
  background-position: center;
}

.slider-scroll, .slider-area :is(.slider-scroll) {
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}
.slider-scroll a, .slider-area :is(.slider-scroll) a {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: var(--clr-common-white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--clr-common-black);
  font-size: 16px;
}
.slider-scroll a:hover {
  background-color: var(--clr-theme-1);
  color: var(--clr-common-white);
}
@media (max-width: 575px) {
  .slider-scroll a, .slider-area :is(.slider-scroll) a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media (max-width: 575px) {
  .slider-scroll, .slider-area :is(.slider-scroll) {
    bottom: 10px;
  }
}

.s-subtitle {
  color: var(--clr-common-black);
  line-height: 1;
  display: block;
}
.s-subtitle i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  margin-right: 12px;
}
.s-subtitle:is(.s-subtitle-2) i {
  color: var(--clr-common-white);
  background: var(--clr-theme-sub-2);
  box-shadow: 0px 10px 10px 0px var(--clr-shadow-1);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .s-subtitle:is(.s-subtitle-2) {
    padding-bottom: 12px;
    font-size: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .s-subtitle:is(.s-subtitle-2) {
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-subtitle:is(.s-subtitle-2) {
    font-size: 15px;
    padding-bottom: 5px;
  }
}
.s-subtitle:is(.s-subtitle-3) {
  padding-left: 60px;
  padding-bottom: 0;
  margin-bottom: 42px;
  position: relative;
}
.s-subtitle:is(.s-subtitle-3)::before {
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: var(--clr-common-black);
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.s-title {
  font-family: "Archivo", sans-serif;
  text-transform: uppercase;
  font-size: 90px;
  font-weight: var(--fw-bold);
  margin-bottom: 0;
  line-height: 1;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .s-title {
    font-size: 70px;
    padding-bottom: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .s-title {
    font-size: 65px;
    padding-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-title {
    font-size: 67px;
    padding-bottom: 29px;
  }
}
@media (max-width: 575px) {
  .s-title {
    font-size: 49px;
    padding-bottom: 11px;
  }
}
.s-title:is(.s-title-2) {
  font-size: 60px;
  padding-bottom: 24px;
  font-weight: 500;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .s-title:is(.s-title-2) {
    padding-bottom: 45px;
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .s-title:is(.s-title-6) {
    font-size: 43px;
  }
}

.s-desc {
  color: var(--clr-common-black);
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .s-desc {
    padding-bottom: 34px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .s-desc {
    padding-bottom: 34px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-desc {
    padding-bottom: 26px;
  }
}
@media (max-width: 575px) {
  .s-desc {
    padding-bottom: 26px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .s-desc br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-desc br {
    display: none;
  }
}

.slider-content-3 {
  margin-left: -115px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .slider-content-3 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 {
    margin-left: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .slider-content-3 {
    margin-top: 70px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-3 .s-title {
    font-size: 60px;
  }
}
.slider-content-3 .s-desc {
  padding-right: 105px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
  .slider-content-3 .s-desc {
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-3 .s-desc {
    padding-bottom: 35px;
  }
}

.slider-content-2 {
  margin-left: -110px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .slider-content-2 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 {
    margin-left: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 p {
    padding-right: 130px;
  }
}
@media (max-width: 575px) {
  .slider-content-2 p {
    display: none;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .slider-content-4 {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-content-4 {
    margin-top: 85px;
  }
}
@media (max-width: 575px) {
  .slider-content-4 {
    margin-top: 80px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .slider-content-5 {
    margin-left: -35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-5 {
    margin-top: 100px;
  }
}
@media (max-width: 575px) {
  .slider-content-5 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .slider-content-6 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-content-6 {
    margin-left: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-6 {
    margin-left: 24px;
  }
}
.slider-content-6 p {
  padding-right: 175px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
  .slider-content-6 p {
    padding-right: 0;
  }
}
.slider-content-6 .s-subtitle {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .slider-content-6 .s-subtitle {
    margin-bottom: 15px;
  }
}

.slider-active-6 .slick-active .s-subtitle-6, .slider-active-6 .slick-active .s-title-6, .slider-active-6 .slick-active .slider-p-6, .slider-active-6 .slick-active .slider-btn-6, .slider-active-6 .slick-active .slider-thumb-6 {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.slider-active-6 .slick-active .s-subtitle-6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.slider-active-6 .slick-active .s-title-6 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.slider-active-6 .slick-active .slider-p-6 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.slider-active-6 .slick-active .slider-btn-6 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}
.slider-active-6 .slick-active .slider-thumb-6 {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .slider-active-6 .slick-active .slider-thumb-6 {
    margin-top: 30px;
  }
}
.slider-active-6 .slick-dots {
  bottom: 55px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 1400px) and (max-width: 1600px) {
  .slider-active-6 .slick-dots {
    left: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active-6 .slick-dots {
    left: 34px !important;
  }
}

.slider-thumb img {
  display: block;
  margin: auto;
  max-width: 100%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-thumb img {
    max-width: 340px;
    transform: translateY(50px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .slider-thumb img {
    max-width: 301px;
    transform: translateY(33px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-thumb img {
    max-width: 65%;
  }
}

.slick-slide:focus {
  outline: none;
}

.slider-scroll-2 {
  position: absolute;
  right: 20px;
  bottom: 184px;
  transform: rotate(-90deg);
}
.slider-scroll-2 a {
  text-decoration: none;
}
.slider-scroll-2 i {
  font-size: 18px;
  color: var(--clr-common-black);
  display: inline-block;
  transform: translateY(4px) rotate(90deg);
}
.slider-scroll-2 span {
  font-size: 13px;
  color: var(--clr-common-black-2);
  padding-left: 15px;
  font-weight: 700;
}
.slider-scroll-2:is(.center-scroll) {
  left: 50%;
  bottom: 95px;
  right: auto;
  transform: translateX(-50%) rotate(-90deg);
}

.slider-scroll-6 {
  position: absolute;
  right: 20px;
  bottom: -30px;
  transform: rotate(-90deg);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 1400px) and (max-width: 1600px) {
  .slider-scroll-6 {
    bottom: -155px;
  }
}
.slider-scroll-6 i {
  font-size: 18px;
  color: var(--clr-common-black);
  display: inline-block;
  transform: translateY(4px) rotate(90deg);
}
.slider-scroll-6 span {
  font-size: 13px;
  color: var(--clr-common-black-2);
  padding-left: 15px;
  font-weight: 700;
}

.slider-scroll-5 i {
  transform: translateY(4px) rotate(0deg);
}

.slider-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider-thumb-4 img {
  max-width: inherit;
}
@media (max-width: 575px) {
  .slider-thumb-4 img {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-thumb-4 img {
    max-width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .slider-thumb-4 img {
    max-width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-thumb-4 img {
    max-width: 110%;
  }
}

@media (max-width: 575px) {
  .single-slider-4 {
    background-image: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-slider-4 {
    background-image: none !important;
  }
}

@media (max-width: 575px) {
  .slider-area-4 {
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-area-4 {
    padding-bottom: 50px;
  }
}

.slider-height-2 {
  min-height: 600px;
}

.single-slider {
  background-size: cover;
  background-position: left;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .single-slider {
    min-height: 690px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-slider {
    min-height: 592px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-slider {
    min-height: 575px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider {
    min-height: 580px;
  }
}
@media (max-width: 575px) {
  .single-slider {
    min-height: 545px;
    background-position: right;
  }
}
.single-slider-2 {
  background-position: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-4 {
    min-height: 780px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    min-height: 780px;
  }
}

.slider-height-6 {
  min-height: 1080px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-6 {
    min-height: 780px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-6 {
    min-height: 680px;
  }
}
@media (max-width: 575px) {
  .slider-height-6 {
    min-height: 780px;
  }
}

.bg-bottom-left {
  background-position: bottom left;
  background-size: cover;
}

.s-subtitle-5 {
  display: block;
  font-family: "shutter_stonestandar", sans-serif;
  color: var(--clr-theme-3);
  font-size: 68px;
  line-height: 1;
  padding-bottom: 6px;
}
@media (max-width: 575px) {
  .s-subtitle-5 {
    font-size: 47px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-subtitle-5 {
    font-size: 50px;
  }
}

.s-title-5 {
  font-family: "Monoment Regular", sans-serif;
  font-size: 90px;
  font-weight: 400;
  padding-bottom: 30px;
}
@media (max-width: 575px) {
  .s-title-5 {
    font-size: 45px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-title-5 {
    font-size: 60px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .s-title-5 {
    font-size: 80px;
  }
}

.sm-shape {
  position: absolute;
  left: -158px;
  bottom: 177px;
  z-index: -1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slider-thumbs.thumb-5 img:nth-child(1) {
    max-width: 400px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-thumbs.thumb-5 img:nth-child(1) {
    transform: translateY(100px);
    max-width: 450px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-thumbs.thumb-5 img:nth-child(2) {
    max-width: 300px;
    left: 74px;
  }
}

.s-dot-style.dot-style:is(.dot-bottom-left-2) ul {
  display: flex;
  transform: none;
}
.s-dot-style.dot-style:is(.dot-bottom-left-2) ul li {
  margin: 0 5px;
}

.s-dot-style .slick-dots li button {
  border-width: 1.5px;
}
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.header-transparent, .header-area:is(.header-transparent) {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .header-transparent, .header-area:is(.header-transparent) {
    position: relative;
    background: var(--clr-common-black);
  }
}
.header-transparent-2 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9;
}

.mobile-header {
  padding: 20px 0;
}
.mobile-header button, .mobile-header a {
  border: 0;
  color: var(--clr-common-black);
  background: transparent;
}
.mobile-header button i, .mobile-header a i {
  font-size: 24px;
}
@media (max-width: 575px) {
  .mobile-header button i, .mobile-header a i {
    font-size: 19px;
  }
}
@media (max-width: 575px) {
  .mobile-header button, .mobile-header a {
    margin-right: 0;
  }
}
.mobile-header button.cart-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.06);
  position: relative;
  color: var(--clr-common-black);
  justify-content: center;
  position: relative;
  display: inline-block;
  align-items: center;
}
.mobile-header button.cart-btn i {
  font-size: 14px;
}
.mobile-header button.cart-btn span {
  position: absolute;
  right: -3px;
  top: -3px;
  font-size: 11px;
  line-height: 20px;
  color: var(--clr-common-white);
  min-width: 20px;
  display: block;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--clr-theme-1);
}
@media (min-width: 768px) and (max-width: 991px) {
  .mobile-header:is(.mobile-header-2) {
    padding: 20px 60px;
  }
}

.header-border {
  border-bottom: 1px solid #e1e1e1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo {
    padding-right: 40px;
  }
}
@media (max-width: 575px) {
  .logo {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main-1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-main-2 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-main-2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding {
    padding: 10px 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-padding {
    padding: 10px 10px;
  }
}
@media (max-width: 575px) {
  .header-padding {
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-2 {
    padding: 10px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-padding-2 {
    padding: 10px 10px;
  }
}
@media (max-width: 575px) {
  .header-padding-2 {
    padding: 10px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-area:is(.header-transparent, .header-area:is(.header-transparent)):is(.transparent-2) {
    position: static;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .header-area:is(.header-transparent, .header-area:is(.header-transparent)):is(.transparent-2) {
    position: static;
  }
}

:is(.single-category) .cat-thumb img {
  max-width: 100%;
  width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
:is(.single-category) .cat-content {
  padding: 40px;
}
:is(.single-category) .cat-content:is(.bottom-left) {
  left: 0;
  bottom: 0;
  right: 0;
}
:is(.single-category) .cat-content:is(.top-left) {
  left: 0;
  top: 0;
  right: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  :is(.single-category) .cat-content {
    padding: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category) .cat-content {
    padding: 13px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  :is(.single-category) .cat-content {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  :is(.single-category) .cat-content {
    padding: 18px;
  }
}
:is(.single-category) .cat-content h4 {
  font-weight: var(--fw-sbold);
  font-size: 20px;
  line-height: 1;
  margin-bottom: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
:is(.single-category) .cat-content h4 a {
  display: inline-block;
}
:is(.single-category) .cat-content h4:hover {
  /*color: var(--clr-theme-1);*/
  color: var(--clr-theme-sub-2);;
}
@media (min-width: 992px) and (max-width: 1199px) {
  :is(.single-category) .cat-content h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category) .cat-content h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  :is(.single-category) .cat-content h4 {
    font-size: 16px;
    margin-bottom: 7px;
  }
}
@media (max-width: 575px) {
  :is(.single-category) .cat-content h4 {
    font-size: 16px;
  }
}
:is(.single-category) .cat-content span.cat-subtitle {
  font-size: 14px;
  color: var(--clr-common-gray);
  line-height: 1;
  display: block;
}
:is(.single-category) .cat-content:is(.cat-content-2) {
  padding: 45px 50px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  :is(.single-category) .cat-content:is(.cat-content-2) {
    padding: 8px 19px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category) .cat-content:is(.cat-content-2) h4 {
    padding-bottom: 15px;
    margin-bottom: 0;
  }
  :is(.single-category) .cat-content:is(.cat-content-2) h4:span:not(:last-child) {
    font-size: 15px;
    padding-bottom: 5px;
  }
}
@media (max-width: 575px) {
  :is(.single-category) .cat-content:is(.cat-content-2) h4 {
    padding-bottom: 5px;
  }
}
@media (max-width: 575px) {
  :is(.single-category) .cat-content:is(.cat-content-2) h4 span.has-underline {
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category) .cat-content:is(.cat-content-2) {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  :is(.single-category) .cat-content:is(.cat-content-2) {
    padding: 20px;
  }
}
:is(.single-category):hover .cat-thumb img {
  transform: scale(1.1);
}

:is(.single-category-2) .cat-thumb img {
  max-width: 100%;
  width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
:is(.single-category-2) .cat-content {
  padding: 40px;
}
:is(.single-category-2) .cat-content:is(.bottom-left) {
  left: 0;
  bottom: 0;
  right: 0;
}
:is(.single-category-2) .cat-content:is(.top-left) {
  left: 0;
  top: 0;
  right: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  :is(.single-category-2) .cat-content {
    padding: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category-2) .cat-content {
    padding: 13px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  :is(.single-category-2) .cat-content {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  :is(.single-category-2) .cat-content {
    padding: 18px;
  }
}
:is(.single-category-2) .cat-content h4 {
  font-weight: var(--fw-sbold);
  font-size: 20px;
  line-height: 1;
  margin-bottom: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
:is(.single-category-2) .cat-content h4 a {
  display: inline-block;
}
:is(.single-category-2) .cat-content h4:hover {
  color: var(--clr-theme-sub-2);
}
@media (min-width: 992px) and (max-width: 1199px) {
  :is(.single-category-2) .cat-content h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category-2) .cat-content h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  :is(.single-category-2) .cat-content h4 {
    font-size: 16px;
    margin-bottom: 7px;
  }
}
@media (max-width: 575px) {
  :is(.single-category-2) .cat-content h4 {
    font-size: 16px;
  }
}
:is(.single-category-2) .cat-content span.cat-subtitle {
  font-size: 14px;
  color: var(--clr-common-gray);
  line-height: 1;
  display: block;
}
:is(.single-category-2) .cat-content:is(.cat-content-2) {
  padding: 45px 50px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  :is(.single-category-2) .cat-content:is(.cat-content-2) {
    padding: 8px 19px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category-2) .cat-content:is(.cat-content-2) h4 {
    padding-bottom: 15px;
    margin-bottom: 0;
  }
  :is(.single-category-2) .cat-content:is(.cat-content-2) h4:span:not(:last-child) {
    font-size: 15px;
    padding-bottom: 5px;
  }
}
@media (max-width: 575px) {
  :is(.single-category-2) .cat-content:is(.cat-content-2) h4 {
    padding-bottom: 5px;
  }
}
@media (max-width: 575px) {
  :is(.single-category-2) .cat-content:is(.cat-content-2) h4 span.has-underline {
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :is(.single-category-2) .cat-content:is(.cat-content-2) {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  :is(.single-category-2) .cat-content:is(.cat-content-2) {
    padding: 20px;
  }
}
:is(.single-category-2):hover .cat-thumb img {
  transform: scale(1.1);
}

.header-top p {
  margin-bottom: 0;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.header-top .nice-select {
  padding: 0px 30px;
  margin-right: 15px;
  height: auto;
  font-size: 13px;
  background-color: transparent;
  line-height: 1;
  border: 0;
  padding-left: 0;
}
.header-top .nice-select > .current {
  color: var(--clr-common-white);
}

.header-top {
  background-color: var(--clr-common-black);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-top {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.topbar-menu ul li {
  display: inline-block;
}
.topbar-menu ul li a {
  text-decoration: none;
  font-size: 13px;
  margin-left: 26px;
  display: inline-block;
  color: var(--clr-common-white);
  padding: 13px 0;
}

.end-text {
  text-align: right;
}

.menu-toggle {
  font-size: 18px;
  border: 0;
  padding: 0;
  line-height: 1;
  background: transparent;
  color: #222222;
}

.header-search-form {
  position: relative;
}
.header-search-form button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header-search-form button[type=submit] {
  border: 0;
  background: transparent;
  color: #222222;
  font-size: 18px;
}
.header-search-form input {
  padding-left: 33px;
  border: 0;
  background-color: transparent;
  width: 150px;
  font-size: 14px;
  color: #222222;
}
.header-search-form input::-webkit-input-placeholder {
  font-size: 14px;
  color: #222222;
}
.header-search-form input:-moz-placeholder {
  font-size: 14px;
  color: #222222;
}
.header-search-form input::-moz-placeholder {
  font-size: 14px;
  color: #222222;
}
.header-search-form input:-ms-input-placeholder {
  font-size: 14px;
  color: #222222;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main-5 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .header-main-5 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.header-main-5 .menu-toggle {
  margin-right: 31px;
}

.main-menu ul li a {
  position: relative;
}
.main-menu ul li a .menu-tag {
  position: absolute;
  color: var(--clr-common-white);
  font-size: 9px;
  padding: 1px 7px 0;
  border-radius: 50px;
  top: 28%;
  right: -28px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 16px;
  background: #01bad4;
}
.main-menu ul li a .menu-tag.yellow {
  background: #ff8506;
}
.main-menu ul li a .menu-tag.red {
  background: #f61a1a;
}
.main-menu ul li.static {
  position: static;
}
.main-menu ul li .sub-menu {
  text-align: left;
  position: absolute;
  min-width: 250px;
  background: var(--clr-common-white);
  padding: 10px 0;
  top: 120%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  z-index: 11;
}
.main-menu ul li .sub-menu li {
  margin: 0;
  display: block;
}
.main-menu ul li .sub-menu li a {
  display: block;
  color: #878787;
  line-height: 1.5;
  padding: 10px 15px !important;
  text-transform: capitalize;
}
.main-menu ul li .sub-menu li a::after {
  display: none;
}
.main-menu ul li .sub-menu li a:hover {
  color: var(--clr-common-black);
}
.main-menu ul li .mega-menu {
  position: absolute;
  top: 120%;
  left: 0;
  width: 980px;
  background: var(--clr-common-white);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  visibility: hidden;
  opacity: 0;
  z-index: 11;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}
.main-menu ul li .mega-menu.mega-full {
  width: 100%;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .mega-menu.mega-menu-2-col {
    left: -5%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li .mega-menu.mega-menu-2-col {
    width: 770px;
  }
}
.main-menu ul li .mega-menu.mega-menu-2-col > li:nth-child(1) {
  width: 19%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li .mega-menu.mega-menu-2-col > li:nth-child(1) {
    width: 33.33%;
  }
}
.main-menu ul li .mega-menu.mega-menu-2-col > li:nth-child(2) {
  width: 19%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li .mega-menu.mega-menu-2-col > li:nth-child(2) {
    width: 33.33%;
  }
}
.main-menu ul li .mega-menu.mega-menu-2-col > li:nth-child(3) {
  width: 32%;
  padding: 30px 21px;
}
.main-menu ul li .mega-menu.mega-menu-2-col > li:nth-child(4) {
  width: 30%;
  padding: 30px 0;
  padding-right: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .mega-menu.mega-menu-3-col {
    width: 700px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li .mega-menu.mega-menu-3-col {
    width: 700px;
  }
}
.main-menu ul li .mega-menu.mega-menu-3-col > li {
  width: 33.33%;
}
.main-menu ul li .mega-menu.mega-menu-20 > li {
  width: 80%;
}
.main-menu ul li .mega-menu.mega-menu-20 > li:first-child {
  width: 20%;
}
.main-menu ul li .mega-menu li {
  display: block;
  margin: 0;
  width: 25%;
  float: left;
  padding: 30px 25px;
}
.main-menu ul li .mega-menu li > a {
  padding: 10px 0 !important;
  border-bottom: 1px solid #cdcdcd;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  display: block;
  color: var(--clr-common-black);
  line-height: inherit;
}
.main-menu ul li .mega-menu li > a::after {
  display: none;
}
.main-menu ul li .mega-menu li ul li {
  width: 100%;
  float: none;
  padding: 0;
}
.main-menu ul li .mega-menu li ul li a {
  padding: 10px 0;
  color: #888888;
  border-bottom: none;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
  display: inline-block;
}
.main-menu ul li .mega-menu li ul li a:hover {
  color: var(--clr-theme-1);
}
.main-menu ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.main-menu-3 ul li:hover > a {
  color: #d4a533 !important;
}
.main-menu .menu-banner-btn {
  display: inline-block;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #000 !important;
  padding: 0 40px;
  text-transform: uppercase;
  background: #ffffff;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.main-menu .menu-banner-btn:hover {
  background: var(--clr-common-black);
  color: var(--clr-common-white) !important;
}

.header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-common-black);
  z-index: 99;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  display: block;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}
.header-sticky.sticky .header-main ul li > a {
  padding: 35px 0;
}
.header-sticky.sticky .header-right a {
  color: #fff;
}
.header-sticky.sticky .header-icon :is(i) {
  color: #fff;
}
.header-sticky.sticky .header-icon button :is(i) {
  color: #000;
}
.header-sticky-white.sticky {
  background: var(--clr-common-white);
}
.header-sticky-white.sticky .header-main ul li > a {
  padding: 35px 0;
}
.header-sticky-white.sticky .header-right a {
  color: var(--clr-common-black);
}
.header-sticky-white.sticky .header-icon :is(i) {
  color: var(--clr-common-black);
}
.header-sticky-white.sticky .header-icon button :is(i) {
  color: #000;
}
.header-sticky-white.sticky.header-main-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-bar-btn {
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
}
.header-bar-btn span {
  display: inline-block;
  width: 100%;
  background: var(--clr-common-white);
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.header-bar-btn span:nth-child(1) {
  top: 0px;
}
.header-bar-btn span:nth-child(2) {
  top: 7px;
}
.header-bar-btn span:nth-child(3) {
  top: 14px;
}
.header-bar-btn-black span {
  background: var(--clr-common-black);
}

.offcanvas__area::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.offcanvas__area .modal.show .modal-dialog {
  transform: none !important;
}
.offcanvas__area .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(100px, 0px);
}
.offcanvas__area .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--clr-common-white);
}
.offcanvas__area .modal-content {
  border: 0;
}
.offcanvas__area-2 .modal.show .modal-dialog {
  transform: none !important;
  left: 0;
}
.offcanvas__area-2 .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(-100px, 0px);
}
.offcanvas__area-2 .modal-dialog {
  left: 0;
  right: auto;
}
.offcanvas__close-btn {
  font-size: 16px;
  color: var(--clr-common-black);
  background: transparent;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: transparent;
  border: 0;
  outline: 0;
}
.offcanvas__close-btn:hover {
  color: var(--clr-theme-1);
}
.offcanvas__close-btn svg {
  width: 22px;
  height: 22px;
}
.offcanvas__close-btn svg path {
  fill: var(--clr-common-black);
}
.offcanvas__action a {
  font-size: 16px;
  color: var(--clr-common-black);
  position: relative;
}
.offcanvas__action a.has-tag {
  padding-right: 15px;
}
.offcanvas__action a .tag {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: var(--clr-common-white);
  background: var(--clr-soft-gray-2);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.offcanvas__action a svg {
  width: 20px;
  height: 22px;
}
.offcanvas__action a svg path {
  fill: var(--clr-common-black);
}
.offcanvas__action a:hover .tag {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
}
.offcanvas__top {
  padding: 40px;
  padding-bottom: 0;
}
.offcanvas__bottom {
  margin-top: 15px;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px 35px;
  background: var(--clr-common-white);
  z-index: 1;
}
.offcanvas__search-btn {
  background: transparent;
  font-size: 22px;
}
.offcanvas__search-btn svg {
  width: 22px;
  height: 22px;
}
.offcanvas__search-btn svg path {
  fill: var(--clr-common-black);
}
.offcanvas__wrapper {
  position: relative;
  height: 100%;
}
.offcanvas__social ul li {
  display: inline-block;
}
.offcanvas__social ul li:not(:last-child) {
  margin-right: 20px;
}
.offcanvas__social ul li a {
  font-size: 14px;
  color: var(--clr-common-black);
}
.offcanvas__content {
  padding-left: 40px;
  padding-right: 40px;
}

/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li:not(:last-child) a {
  border-bottom: 1px solid #e1e1e1;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  color: var(--clr-common-black);
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 0;
  padding-top: 15px;
}

.mean-container .mean-nav ul li:hover > a {
  color: var(--clr-theme-sub-2);
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 0px;
  height: 15px;
  line-height: 14px;
  border-bottom: none;
  padding: 0;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  top: 8px;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: transparent;
  color: var(--clr-theme-1);
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  background: transparent;
  color: var(--clr-common-black);
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  color: var(--clr-common-black);
}

.subscribe-form input {
  width: 100%;
  border: 0;
  line-height: 1;
  text-align: center;
  border-bottom: 2px solid var(--clr-soft-gray-5);
  padding: 20px;
  padding-bottom: 18px;
  background-color: transparent;
  margin-bottom: 30px;
}
.subscribe-form input::-webkit-input-placeholder {
  font-size: 13px;
  color: var(--clr-soft-gray-3);
}
.subscribe-form input:-moz-placeholder {
  font-size: 13px;
  color: var(--clr-soft-gray-3);
}
.subscribe-form input::-moz-placeholder {
  font-size: 13px;
  color: var(--clr-soft-gray-3);
}
.subscribe-form input:-ms-input-placeholder {
  font-size: 13px;
  color: var(--clr-soft-gray-3);
}
.subscribe-form:is(.gray-form) input {
  border-color: var(--clr-common-black-2);
}
.subscribe-form:is(.gray-form) input::-webkit-input-placeholder {
  color: var(--clr-soft-gray-2);
}
.subscribe-form:is(.gray-form) input:-moz-placeholder {
  color: var(--clr-soft-gray-2);
}
.subscribe-form:is(.gray-form) input::-moz-placeholder {
  color: var(--clr-soft-gray-2);
}
.subscribe-form:is(.gray-form) input:-ms-input-placeholder {
  color: var(--clr-soft-gray-2);
}

.subscribe-area {
  margin-bottom: -1px;
}
@media (max-width: 575px) {
  .subscribe-area {
    padding: 45px 0;
  }
}

.f-widget-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 29px;
}
.f-widget-title:is(.white-color) {
  color: var(--clr-common-white);
}

.footer-1 {
  padding-bottom: 47px;
}
.footer-1 ul li {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  text-decoration: none;
  font-size: 14px;
  display: block;
  color: var(--clr-soft-gray-2);
  list-style: none;
  padding-bottom: 6px;
}
.footer-1 ul li:hover {
  padding-left: 12px;
}
.footer-1 ul li:hover {
  color: var(--clr-heading-primary);
}
.footer-1 ul li a {
  text-decoration: none;
  font-size: 14px;
  display: block;
  color: var(--clr-soft-gray-2);
}
.footer-1 ul li a:hover {
  padding-left: 12px;
}
.footer-1 ul li a:hover {
  color: var(--clr-heading-primary);
}
.footer-1:is(.black-bg) ul li {
  color: #888;
}
.footer-1:is(.black-bg) ul li a {
  color: #888888;
}
.footer-1:is(.black-bg) ul li:hover {
  color: var(--clr-common-white);
}
.footer-1:is(.black-bg) ul li:hover a {
  color: var(--clr-common-white);
}
.footer-1:is(.black-bg) p {
  color: #888;
}
@media (max-width: 575px) {
  .footer-1:is(.black-bg) p {
    margin-bottom: 10px;
  }
}
.footer-1:is(.black-bg) p span {
  color: var(--clr-common-white);
}

.footer-transparent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-transparent {
    position: relative;
  }
}

.copyright-text {
  /*padding: 53px 0;*/
}
.copyright-text p {
  color: var(--clr-soft-gray-2);
  margin-bottom: 0;
  padding-bottom: 14px;
}
.copyright-text p span {
  color: var(--clr-common-black);
}
.copyright-text:is(.copyright-text-2) {
  padding-top: 45px;
  padding-bottom: 50px;
}
.copyright-text:is(.copyright-text-2) p {
  color: var(--clr-soft-gray-11);
}
.copyright-text:is(.copyright-text-3) {
  padding-top: 20px;
}
.copyright-text-7 p {
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .copyright-text-7 p {
    color: #222;
  }
}

a#scrollUp {
  position: fixed;
  right: 0;
  opacity: 1 !important;
  transform: rotate(-90deg);
  bottom: 70px;
  text-decoration: none;
  text-align: center;
  z-index: 99 !important;
}
a#scrollUp i {
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 18px;
  columns: var(--clr-common-black);
  background: var(--clr-common-white);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
}
a#scrollUp span {
  font-weight: var(--fw-bold);
  font-size: 13px;
  padding-left: 11px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  display: inline-block;
  color: var(--clr-common-black-2);
}
a#scrollUp:hover i {
  background-color: var(--clr-theme-1);
  color: var(--clr-common-white);
}
a#scrollUp:hover span {
  color: var(--clr-theme-1);
}
@media (max-width: 575px) {
  a#scrollUp {
    bottom: 62px;
    right: -20px;
  }
}

.footer-social a {
  font-size: 13px;
  color: var(--clr-common-white);
  text-decoration: none;
  font-weight: var(--fw-sbold);
  padding: 0 31.5px;
}
.footer-social a:hover {
  color: var(--clr-theme-sub-2);
}
@media (max-width: 575px) {
  .footer-social a {
    padding: 0 8.5px;
  }
}

.footer-social-2 {
  border-bottom: 1px solid var(--clr-common-black-3);
}
@media (max-width: 575px) {
  .footer-social-2 {
    padding-bottom: 50px;
  }
}

.subscribe-area.black-bg input {
  color: #fff;
}

hr {
  opacity: 1;
}

.subscribe-area-2 .p-title {
  color: var(--clr-common-white);
}
.subscribe-area-2 .p-desc {
  color: #888888;
}
.subscribe-area-2 input {
  border-bottom-color: #444444;
}

.copyright-actions a {
  font-size: 14px;
  color: #888888;
  display: inline-block;
  text-decoration: none;
}
.copyright-actions a:not(:last-child) {
  margin-right: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .copyright-actions a:not(:last-child) {
    margin-right: 25px;
  }
}
.copyright-actions a:hover {
  color: #fff;
}
.copyright-actions-2 a {
  color: #222;
}
.copyright-actions-2 a:hover {
  color: #222;
}

.copyright-text-4 p {
  padding-bottom: 0;
}

.copyright-text-6 {
  padding: 30px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .copyright-text-6 p {
    margin-bottom: 10px;
  }
}

.copyright-border {
  border-top: 1px solid #333333;
}

.footer-pl-30 {
  padding-left: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-pl-30 {
    padding-left: 0;
  }
}

.footer-ml--20 {
  margin-left: -20px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-ml--20 {
    margin-left: 0;
  }
}

.footer-ml--30 {
  margin-left: -30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-ml--30 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .category-area {
    padding-bottom: 25px;
  }
}
@media (max-width: 575px) {
  .category-area {
    padding-bottom: 20px;
  }
}

.cat-content span {
  display: block;
}

.category__item {
  position: relative;
}
.category__item .link-btn {
  color: #999;
  font-size: 14px;
}
.category__item .link-btn:hover {
  color: var(--clr-common-black);
}
.category__item:hover .category__content {
  transform: translate(-50%, 30px);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .category__item:hover .category__content {
    transform: translate(-50%, 10px);
  }
}
.category__title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 13px;
}
.category__tag a {
  font-size: 14px;
  color: #d4a533;
}
.category__content {
  position: absolute;
  bottom: 90px;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  transition: all 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .category__content {
    bottom: 70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .category__content {
    bottom: 45px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .category__content {
    bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category__content {
    bottom: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .category__content {
    bottom: 40px;
  }
}
@media (max-width: 575px) {
  .category__content {
    bottom: 50px;
  }
}
.category__br-right {
  border-right: 1px solid #e5e5e5;
}
@media (max-width: 575px) {
  .category__br-right {
    border-right: 0;
  }
}
.category__br-tp {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .top-selling-area {
    padding-bottom: 75px;
  }
}
@media (max-width: 575px) {
  .top-selling-area {
    padding-bottom: 45px;
  }
}

.subscribe-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: var(--clr-common-white);
}
.popup-title {
  font-size: 60px;
  color: var(--clr-common-black);
  margin-bottom: 0;
  padding-bottom: 19px;
  font-weight: 400;
}

.popup-desc {
  color: var(--clr-common-black);
  margin-bottom: 0;
  padding-bottom: 38px;
  font-size: 14px;
  color: #555;
}

.comming-countdown .countdown-inner ul li {
  display: inline-block;
  padding-right: 15px;
  text-transform: uppercase;
  font-size: 14px;
  color: #999999;
}
.comming-countdown .countdown-inner ul li span {
  font-size: 36px;
  color: var(--clr-common-black);
  padding-right: 5px;
}

.subscribe-thumb {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
}

.subscribe-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.s-popup-padding {
  padding-top: 295px;
  padding-bottom: 282px;
}

.subscribe-thumb {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.subscribe-form-2 input {
  width: 400px;
  height: 50px;
  float: left;
  color: var(--clr-soft-gray-3);
  padding-left: 27px;
  border: 1px solid var(--clr-border-1);
  font-size: 14px;
}
.subscribe-form-2 input::placeholder {
  color: #999999;
}
.subscribe-form-2 input:focus {
  border-color: var(--clr-common-black);
}
.subscribe-form-2 button {
  height: 50px;
  background-color: var(--clr-common-black);
  line-height: 50px;
  font-size: 13px;
  text-transform: uppercase;
  padding-top: 0;
  padding: 0 60px;
  color: var(--clr-common-white);
  padding-bottom: 0;
  margin-left: 10px;
  font-weight: var(--fw-sbold);
}
.subscribe-form-2 button:hover {
  background-color: #f57d5f;
}

.popup-social a {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  margin-right: 10px;
  color: var(--clr-soft-gray-8);
  border: 1px solid var(--clr-border-2);
}
.popup-social a:hover {
  background-color: var(--clr-common-black);
  border-color: var(--clr-common-black);
  color: var(--clr-common-white);
}

.product-tab button {
  font-size: 14px;
  border: 0;
  padding: 0 0;
  background: transparent;
  color: #666666;
  margin-right: 19px;
}
.product-tab button .count {
  transform: translateY(-5px);
  display: inline-block;
  padding-left: 4px;
}
.product-tab button.active {
  color: #222;
}

@media (max-width: 575px) {
  .lookbook-area {
    padding-bottom: 50px;
  }
}

.product-thumb img {
  width: 100%;
}

.trending-active-2 .owl-dots {
  margin-top: -5px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-area {
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-area {
    padding-bottom: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-area-5 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .area-left {
    margin-top: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-area-wrapper-5 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 575px) {
  .product-area-wrapper-5 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .trending-slider-wrapper-5 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 575px) {
  .trending-slider-wrapper-5 {
    padding-left: 0;
    padding-right: 0;
  }
}

.lookbook-height {
  min-height: 700px;
}

.lookbook-content {
  position: relative;
  z-index: 1;
  margin-top: 180px;
  text-align: center;
}
.lookbook-content span {
  font-size: 14px;
  font-weight: 600;
  color: #d4a533;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.lookbook-content p {
  color: #ffffff;
  padding: 0 660px;
  margin-bottom: 75px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .lookbook-content p {
    padding: 0 590px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .lookbook-content p {
    padding: 0 430px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lookbook-content p {
    padding: 0 325px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lookbook-content p {
    padding: 0 225px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lookbook-content p {
    padding: 0 110px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .lookbook-content p {
    padding: 0;
  }
}
.lookbook-content .play-btn {
  position: absolute;
  top: 43%;
  left: 25%;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 62px;
  text-align: center;
  font-size: 13px;
  color: var(--clr-common-black);
  z-index: 99;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .lookbook-content .play-btn {
    left: 22%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lookbook-content .play-btn {
    left: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lookbook-content .play-btn {
    left: 11%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lookbook-content .play-btn {
    left: 4%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lookbook-content .play-btn {
    top: 3%;
    left: 5%;
  }
}
@media (max-width: 575px) {
  .lookbook-content .play-btn {
    top: -25%;
    left: 39%;
  }
}
.lookbook-content .play-btn i {
  padding-left: 3px;
}

.lookbook-title {
  font-size: 68px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 13px;
}
@media (max-width: 575px) {
  .lookbook-title {
    font-size: 50px;
  }
}

.lookbook-thumb {
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lookbook-thumb {
    width: 39%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .lookbook-thumb {
    width: 65%;
  }
}

.product__filter-wrapper {
  border-left: 1px solid #e6e6e6;
}
.product__filter-item .accordion-item {
  position: relative;
  border: 1px solid #e6e6e6;
  border-right: 0;
  border-left: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__filter-item .accordion-item {
    margin-bottom: 15px;
  }
}
.product__filter-item .accordion-button {
  border: 0;
  background: #f7f7f7;
  border-right: 1px solid #e6e6e6;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--clr-common-black);
  box-shadow: none;
}
.product__filter-item .accordion-button::after {
  position: absolute;
  content: "";
  right: 20px;
  top: 46%;
  border-bottom: 1px solid #666666;
  border-right: 1px solid #666666;
  height: 8px;
  width: 8px;
  background-image: none;
  transform: translateY(-6px) rotate(-135deg);
}
.product__filter-item .accordion-button.collapsed {
  background-color: #fff;
}
.product__filter-item .accordion-button.collapsed::after {
  transform: translateY(-6px) rotate(45deg);
}
.product__filter-item .accordion-button:focus {
  box-shadow: none;
  outline: none;
}
.product__filter-item .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}
.product__filter-item .accordion-body {
  padding: 0;
}
.product__filter-item .accordion-item:last-of-type .accordion-collapse {
  border: 0;
  border-radius: 0;
}
.product__filter-content {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 20px;
  width: 300px;
  height: 240px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  z-index: 11;
}
.product__filter-content.product-filter-price {
  height: 90px;
}
.product__filter-price {
  padding-top: 10px;
}
.product__nav-btn.active .line {
  background: var(--clr-common-black);
}
.product__nav-btn .line {
  display: inline-block;
  width: 2px;
  height: 11px;
  background-color: #e1e1e1;
}
.product__nav-btn-2 {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 4px;
  position: relative;
  background: transparent;
  margin-right: 30px;
}
@media (max-width: 575px) {
  .product__nav-btn-2 {
    margin-bottom: 15px;
  }
}
.product__nav-btn-2::after {
  transition: 0.3s ease-in;
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: var(--clr-common-black);
}
.product__nav-btn-2.active, .product__nav-btn-2:hover {
  color: var(--clr-common-black);
}
.product__nav-btn-2.active::after, .product__nav-btn-2:hover::after {
  width: 100%;
}
.product__nav-btn-3 {
  opacity: 0.4;
}
.product__nav-btn-3.active {
  opacity: 1;
}
.product__offcanvas-area .modal.show .modal-dialog {
  transform: none !important;
}
.product__offcanvas-area .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(100px, 0px);
}
.product__offcanvas-area .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--clr-common-white);
}
.product__offcanvas-area .modal-content {
  border: 0;
}
.product__offcanvas-area-2 .modal.show .modal-dialog {
  transform: none !important;
  left: 0;
}
.product__offcanvas-area-2 .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(-100px, 0px);
}
.product__offcanvas-area-2 .modal-dialog {
  left: 0;
  right: auto;
  margin: 0;
}
.product__offcanvas-close {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  margin-bottom: 45px;
}
.product__offcanvas-close .product-offcanvas-close-btn {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-common-black);
  background: transparent;
  display: flex;
  align-items: center;
}
.product__offcanvas-close .product-offcanvas-close-btn i {
  font-size: 20px;
  margin-right: 10px;
}
.product__offcanvas-wrapper {
  padding: 40px 40px;
}
.product__details-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product__details-content {
    padding-top: 20px;
  }
}
.product__details-content .product-des {
  color: #666666;
  margin-bottom: 30px;
  padding-right: 50px;
}
.product__details-content .product-id {
  color: #666666;
  margin-bottom: 0px;
  padding-right: 50px;
}
.product__details-content.product-details-sidebar-sticky {
  position: sticky;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-content-2 {
    padding-top: 0;
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-content-2 {
    margin-top: 50px;
  }
}
.product__details-thumb .product-nav-thumb-wrapper {
  position: relative;
}
.product__details-thumb .product-nav-thumb-wrapper .product-img-zoom {
  position: absolute;
  top: 30px;
  right: 30px;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: var(--clr-common-black);
  background: #fff;
  text-align: center;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  border-radius: 50%;
}
.product__details-thumb .product-nav-thumb-wrapper .product-img-zoom:hover {
  background: #222;
  color: #fff;
}
.product__details-thumb-scroll img {
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-grid-thumb {
    padding-right: 0;
  }
}
.product__details-nav {
  flex: 0 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-nav {
    margin-right: 0;
  }
}
.product__details-nav .nav-tabs {
  margin: 0;
  border: 0;
}
@media (max-width: 575px) {
  .product__details-nav .nav-tabs {
    justify-content: space-between;
  }
}
.product__details-nav .nav-tabs .nav-item {
  margin-bottom: 15px;
}
.product__details-nav .nav-tabs .nav-item .nav-link {
  padding: 0;
  border: 0;
  margin: 0;
  border: 1px solid transparent;
}
.product__details-nav .nav-tabs .nav-item .nav-link.active {
  border-color: #d6d6d6;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .product__details-nav-scroll {
    margin-right: 10px;
  }
}
@media (max-width: 575px) {
  .product__details-nav-scroll .nav-tabs {
    justify-content: space-evenly;
  }
}
.product__details-nav-scroll .nav-tabs .nav-item {
  margin-bottom: 10px;
}
.product__details-nav-scroll .nav-tabs .nav-item .nav-link:hover {
  border-color: #d6d6d6;
}
.product__details-price {
  margin-bottom: 5px;
}
.product__details-price .price {
  font-size: 18px;
  color: var(--clr-common-black);
}
.product__details-rating > ul li {
  display: inline-block;
}
.product__details-rating > ul li a {
  color: #ffae00;
  font-size: 13px;
}
.product__details-customer span {
  font-size: 13px;
  color: #666666;
}
.product__details-color span {
  font-size: 14px;
  color: var(--clr-common-black);
  width: 15%;
  display: inline-block;
}
@media (max-width: 575px) {
  .product__details-color span {
    margin-bottom: 15px;
  }
}
.product__details-color ul {
  line-height: 1;
}
.product__details-color ul li {
  display: inline-block;
}
.product__details-color ul li:not(:last-child) {
  margin-right: 5px;
}
.product__details-color ul li a {
  display: inline-block;
  width: 35px;
  height: 20px;
  background-color: #222;
  box-shadow: 0 0 0 1px #dddddd, inset 0 0 0 2px #fff;
}
.product__details-color ul li a.brown {
  background-color: brown;
}
.product__details-color ul li a.blue {
  background-color: blue;
}
.product__details-color ul li a.red {
  background-color: red;
}
.product__details-color ul li a.white {
  background-color: white;
}
.product__details-color ul li a.active, .product__details-color ul li a:hover {
  box-shadow: 0 0 0 1px #151515, inset 0 0 0 2px #fff;
}
.product__details-size span {
  font-size: 14px;
  color: var(--clr-common-black);
  width: 15%;
  display: inline-block;
}
@media (max-width: 575px) {
  .product__details-size span {
    margin-bottom: 15px;
  }
}
.product__details-size ul {
  line-height: 1;
}
.product__details-size ul li {
  display: inline-block;
}
.product__details-size ul li:not(:last-child) {
  margin-right: 5px;
}
.product__details-size ul li a {
  display: inline-block;
  width: 35px;
  height: 20px;
  line-height: 18px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  overflow: hidden;
}
.product__details-size ul li a.active, .product__details-size ul li a:hover {
  /* border-color: var(--clr-common-black);
  color: var(--clr-common-black); */
  background: black;
  color: white;
}
.product__details-size ul li a.unavailable {
  position: relative;
  cursor: not-allowed;
  color: #e1e1e1;
}
.product__details-size ul li a.unavailable::after {
  position: absolute;
  content: "";
  left: -3px;
  top: -51px;
  width: 1px;
  height: 142px;
  transform: rotate(60deg);
  background-color: #e1e1e1;
}
.product__details-size .product-size-guide-btn {
  font-size: 11px;
  font-weight: 500;
  color: var(--clr-common-black);
  background-color: transparent;
  outline: 0;
  text-transform: uppercase;
}
.product__details-quantity .cart-plus-minus {
  position: relative;
  width: 85px;
}
.product__details-quantity .cart-plus-minus input {
  border: 0px;
  outline: 0px;
  background: none;
  font-weight: 400;
  color: var(--clr-common-black);
  font-size: 14px;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  padding: 0 0;
  width: 100%;
  border-bottom: 2px solid #e1e1e1;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.product__details-quantity .cart-plus-minus .qtybutton {
  font-size: 24px;
  color: var(--clr-common-black);
  display: inline-block;
  position: absolute;
  top: 43%;
  height: 22px;
  width: auto;
  background: transparent;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.product__details-quantity .cart-plus-minus .dec {
  left: 0;
}
.product__details-quantity .cart-plus-minus .inc {
  right: 0;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px), only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-quantity .product-add-cart .s-btn {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1800px), only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-quantity .product-add-cart-2 .s-btn {
    padding-left: 75px;
    padding-right: 75px;
  }
}
.product__details-compare {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.product__details-compare ul li {
  display: inline-block;
  margin-right: 30px;
}
.product__details-compare ul li a {
  color: #666666;
  font-size: 14px;
}
.product__details-compare ul li a i {
  color: var(--clr-common-black);
  margin-right: 5px;
}
.product__details-compare ul li a:hover {
  color: #d4a533;
}
.product__details-compare ul li a:hover i {
  color: #d4a533;
}
.product__details-meta ul li {
  margin-bottom: 3px;
}
.product__details-meta ul li span {
  font-size: 14px;
  display: inline-block;
  width: 20%;
  color: var(--clr-common-black);
}
@media (max-width: 575px) {
  .product__details-meta ul li span {
    width: inherit;
  }
}
.product__details-meta ul li p {
  display: inline-block;
  color: #666666;
  margin-bottom: 0;
  width: 75%;
}
@media (max-width: 575px) {
  .product__details-meta ul li p {
    width: inherit;
  }
}
.product__details-meta ul li p span {
  width: inherit;
  color: #666666;
}
.product__details-meta ul li p a:hover, .product__details-meta ul li p span:hover {
  color: blue;
}
.product__details-social span {
  font-size: 14px;
  color: var(--clr-common-black);
  width: 21%;
  display: inline-block;
}
@media (max-width: 575px) {
  .product__details-social span {
    margin-bottom: 15px;
  }
}
.product__details-social ul li {
  display: inline-block;
  margin-right: 5px;
}
.product__details-social ul li a {
  display: inline-block;
  font-size: 16px;
  color: #cbcbcb;
  border: 1px solid #cbcbcb;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border-radius: 50%;
  text-align: center;
}
.product__details-social ul li a:hover {
  color: #fff;
  background-color: var(--clr-common-black);
  border-color: var(--clr-common-black);
}
.product__details-sizetab-top span {
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 10px;
}
.product__details-sizetab-top h3 {
  font-size: 36px;
  font-weight: 400;
  color: var(--clr-common-black);
}
.product__details-sizetab-modal .modal-dialog {
  max-width: 875px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__details-sizetab-modal .modal-dialog {
    max-width: 650px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__details-sizetab-modal .modal-dialog {
    max-width: 500px;
  }
}
@media (max-width: 575px) {
  .product__details-sizetab-modal .modal-dialog {
    max-width: 300px;
  }
}
.product__details-sizetab-modal .modal-content {
  padding: 50px 0px;
  padding-bottom: 0px;
}
.product__details-sizetab .product-details-sizetab-nav ul li {
  display: inline-block;
  margin: 0 18px;
}
@media (max-width: 575px) {
  .product__details-sizetab .product-details-sizetab-nav ul li {
    margin: 0 7px;
  }
}
.product__details-sizetab .product-details-sizetab-nav ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  text-transform: uppercase;
}
.product__details-sizetab .product-details-sizetab-nav ul li a:hover {
  color: var(--clr-common-black);
}
.product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs {
  border: none;
  border-bottom: 1px solid #eaeaea;
}
.product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link {
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: #666666;
  text-transform: capitalize;
  margin: 0;
  position: relative;
  padding: 0;
  padding-bottom: 10px;
  margin: 0 22px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link {
    margin: 0 15px;
  }
}
@media (max-width: 575px) {
  .product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link {
    margin: 0 20px;
  }
}
.product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link::after {
  position: absolute;
  content: "";
  left: auto;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: var(--clr-common-black);
}
.product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link.active, .product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link:hover {
  background: transparent;
  color: var(--clr-common-black);
}
.product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link.active::after, .product__details-sizetab .product-details-sizetab-nav-2 .nav-tabs .nav-item .nav-link:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
.product__details-sizetab-content {
  padding: 40px 40px;
  padding-top: 0;
}
@media (max-width: 575px) {
  .product__details-sizetab-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.product__details-sizetab-accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.product__details-sizetab-accordion .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__details-sizetab-accordion .accordion-item {
    overflow: hidden;
    overflow-x: scroll;
  }
}
@media (max-width: 575px) {
  .product__details-sizetab-accordion .accordion-item {
    overflow: hidden;
    overflow-x: scroll;
  }
}
.product__details-sizetab-accordion .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-common-black);
  padding: 23px 0;
  border: 0;
}
.product__details-sizetab-accordion .accordion-button:focus {
  box-shadow: none;
}
.product__details-sizetab-accordion .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
}
.product__details-sizetab-accordion .accordion-collapse {
  border: 0;
}
.product__details-sizetab-accordion .accordion-button:not(.collapsed) {
  color: var(--clr-common-black);
  background-color: var(--clr-common-white);
  box-shadow: none;
}
.product__details-sizetab-accordion .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.product__details-sizetab-accordion .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
  border: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__details-sizetab .product-size-table {
    width: 576px;
  }
}
@media (max-width: 575px) {
  .product__details-sizetab .product-size-table {
    width: 576px;
  }
}
.product__details-sizetab .product-size-table th {
  font-size: 16px;
  color: var(--clr-common-black);
  font-weight: 600;
  border: none;
  background: #f4f4f4;
}
.product__details-sizetab .product-size-table tr {
  border: none;
}
.product__details-sizetab .product-size-table tr:nth-child(2n+2) {
  background: #f4f4f4;
}
.product__details-sizetab .product-size-table tr:hover {
  background: #e0e0e0;
}
.product__details-sizetab .product-size-table td {
  border: none;
  width: 20%;
}
.product__details-sizetab .product-size-table td:hover {
  color: var(--clr-common-white);
  background: var(--clr-common-black);
}
.product__details-sizetab .product-size-table-2 p {
  color: var(--clr-common-black);
  margin-bottom: 25px;
}
.product__details-sizetab .product-measurements-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .product__details-sizetab .product-measurements-title {
    font-size: 15px;
  }
}
.product__details-sizetab .product-measurements-cm .nav-tabs {
  border: 1px solid #e1e1e1;
}
.product__details-sizetab .product-measurements-cm .nav-tabs .nav-item .nav-link {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--clr-common-white);
  border: none;
  background: #e1e1e1;
  border-radius: 0;
}
.product__details-sizetab .product-measurements-cm .nav-tabs .nav-item .nav-link.active {
  color: var(--clr-common-black);
  background: #f4f4f4;
}
.product__details-sizetab .product-guide-thumb img {
  width: 100%;
}
.product__details-sizetab .product-guide-content h3 {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-common-black);
  margin-bottom: 0;
}
.product__details-sizetab .product-guide-content p {
  color: var(--clr-common-black);
  margin-bottom: 0;
}
.product__details-sizetab-bottom .attention p {
  font-size: 16px;
  color: var(--clr-common-black);
}
.product__details-sizetab-bottom .attention p span {
  font-weight: 500;
}
.product__details-sizetab-bottom .note h3 {
  font-size: 16px;
  font-weight: 400;
  color: var(--clr-common-black);
}
.product__details-sizetab-bottom .note ul li {
  font-size: 16px;
  color: var(--clr-common-black);
  position: relative;
  padding-left: 17px;
}
.product__details-sizetab-bottom .note ul li:not(:last-child) {
  margin-bottom: 10px;
}
.product__details-sizetab-bottom .note ul li::after {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  width: 6px;
  height: 6px;
  background: #666666;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.product__details-info ul li {
  display: flex;
  padding: 15px 30px;
}
@media (max-width: 575px) {
  .product__details-info ul li {
    display: block;
  }
}
.product__details-info ul li:nth-child(2n+1) {
  background: #f6f6f6;
}
.product__details-info ul li h4 {
  font-size: 16px;
  color: var(--clr-common-black);
  margin-bottom: 0;
  display: inline-block;
  width: 25%;
}
@media (max-width: 575px) {
  .product__details-info ul li h4 {
    display: block;
    width: inherit;
    margin-bottom: 10px;
  }
}
.product__details-info ul li span {
  font-size: 16px;
  color: var(--clr-common-black);
}
.product__details-description .product-additional-des {
  padding: 0 108px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product__details-description .product-additional-des {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-description .product-additional-des {
    padding-left: 0;
    padding-right: 0;
  }
}
.product__details-review .product-review-top {
  margin-bottom: 20px;
}
.product__details-review .product-review-name h4, .product__details-review .product-review-name span {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
}
.product__details-review .product-review-name span {
  color: #999999;
}
.product__details-review .product-review-item {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ebebeb;
  margin-right: 10PX;
}
.product__details-review .product-review-item p {
  color: #666666;
  padding-right: 40px;
}
.product__details-review .product-review-rating ul li {
  display: inline-block;
}
.product__details-review .product-review-rating ul li a {
  font-size: 14px;
  color: #999999;
}
.product__details-review .product-review-rating ul li a:hover {
  color: #ffae00;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-review .product-review-form {
    padding-left: 0;
  }
}
.product__details-review .product-review-form .product-review-title {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.product__details-review .product-review-form .product-review-title span {
  color: #999999;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .product__details-review .product-review-form .product-review-title span {
    margin-left: 0;
  }
}
.product__details-review .product-review-form .product-review-form-des {
  color: #666666;
}
.product__details-review .product-review-form .product-review-form-rating p {
  color: #666;
  margin-bottom: 3px;
}
.product__details-review .product-review-form .product-review-form-rating ul li {
  display: inline-block;
}
.product__details-review .product-review-form .product-review-form-rating ul li a {
  color: #999999;
}
.product__details-review .product-review-form .product-review-form-rating ul li a:hover {
  color: #d4a533;
}
.product__details-review .product-review-form .product-review-input {
  margin-bottom: 20px;
}
.product__details-review .product-review-form .product-review-input label {
  display: block;
  font-size: 14px;
  color: #666666;
  margin-bottom: 5px;
}
.product__details-review .product-review-form .product-review-input input, .product__details-review .product-review-form .product-review-input textarea {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  outline: 0;
  color: #222;
}
.product__details-review .product-review-form .product-review-input input:focus, .product__details-review .product-review-form .product-review-input textarea:focus {
  border-color: #ffae00;
}
.product__details-review .product-review-form .product-review-input textarea {
  padding: 15px;
  height: 200px;
  resize: none;
  line-height: 1.2;
}
.product__details-review .product-review-form .product-review-agree input {
  width: 17px;
  height: 17px;
  border: 1px solid #d7d7d7;
  margin-right: 10px;
}
.product__details-review .product-review-form .product-review-agree label {
  color: #666666;
}
@media (max-width: 575px) {
  .product__details-review .product-review-form .product-review-btn button {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.product__details-banner-ptb-230 {
  padding-top: 230px;
  padding-bottom: 230px;
}
.product__details-banner-text {
  padding-top: 55px;
  padding-bottom: 55px;
}
.product__details-banner-text p {
  color: #999999;
  font-size: 16px;
  line-height: 30px;
  padding: 0 53px;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product__details-banner-text p {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__details-banner-text p {
    padding: 0 70px;
  }
}
@media (max-width: 575px) {
  .product__details-banner-text p {
    padding: 0 0;
  }
}
.product__details-video-wrapper {
  height: 700px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__details-video-wrapper {
    height: 500px;
  }
}
.product__details-video-wrapper iframe {
  width: 100%;
  height: 100%;
  margin: 0;
}
.product__details-accordion .accordion-item {
  margin-bottom: 40px;
}
.product__details-accordion .accordion-button {
  font-size: 14px;
  text-transform: uppercase;
  color: #222;
  font-weight: 600;
  padding: 0;
  border: 0;
  position: relative;
  box-shadow: none;
  background: #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}
.product__details-accordion .accordion-button::after {
  position: absolute;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  content: "\f068";
  font-family: "Font Awesome 5 Pro";
  background: none;
  font-size: 14px;
  color: #222;
  font-weight: 400;
  height: inherit;
}
.product__details-accordion .accordion-button.collapsed {
  color: #999999;
}
.product__details-accordion .accordion-button.collapsed::after {
  content: "\f067";
}
.product__details-accordion .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}
.product__details-accordion .accordion-collapse {
  border: 0;
}
.product__details-accordion .accordion-body {
  padding: 0;
}
.product__details-accordion .product-description-wrapper p {
  color: #666666;
  line-height: 30px;
}
.product__details-accordion .product-description-list ul {
  line-height: 1;
}
.product__details-accordion .product-description-list ul li {
  line-height: 1;
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
  display: flex;
}
.product__details-accordion .product-description-list ul li i {
  margin-right: 10px;
}
.product__zoom-content p {
  color: #555555;
}
.product__zoom-content p:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__zoom-thumb {
    margin-bottom: 20px;
  }
}
.product__info-btn .nav-tabs {
  border: 0;
  margin: 0;
}
@media (max-width: 575px) {
  .product__info-btn .nav-tabs {
    display: inherit;
  }
}
.product__info-btn .nav-tabs .nav-item .nav-link {
  font-size: 14px;
  text-transform: uppercase;
  color: #999999;
  position: relative;
  border: 0;
  margin: 0;
  margin-right: 50px;
  padding: 0;
  padding-bottom: 4px;
}
@media (max-width: 575px) {
  .product__info-btn .nav-tabs .nav-item .nav-link {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.product__info-btn .nav-tabs .nav-item .nav-link::after {
  position: absolute;
  content: "";
  left: auto;
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background: #222;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.product__info-btn .nav-tabs .nav-item .nav-link.active, .product__info-btn .nav-tabs .nav-item .nav-link:hover {
  color: #222;
}
.product__info-btn .nav-tabs .nav-item .nav-link.active::after, .product__info-btn .nav-tabs .nav-item .nav-link:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
.product__countdown {
  margin-bottom: 7px;
}
.product__countdown-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.product__countdown-wrapper {
  padding: 25px 30px 20px;
  border: 1px solid #ebebeb;
}
@media (max-width: 575px) {
  .product__countdown-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.product__countdown-wrapper .progress {
  height: 7px;
  background: #ebebeb;
  margin-bottom: 5px;
}
.product__countdown-wrapper .progress-bar {
  background-color: #cc1414;
}
.product__countdown ul li {
  display: inline-block;
  font-size: 11px;
  color: #999999;
  margin-bottom: 0;
  text-transform: uppercase;
}
.product__countdown ul li span {
  font-size: 14px;
  font-weight: 600;
  color: #222;
  display: inline-block;
}
.product__countdown ul li span.colon {
  margin: 0 5px;
}
.product__countdown-sold span {
  color: #999999;
  font-weight: 14px;
  margin-right: 5px;
}
.product__countdown-sold p {
  color: #222;
  font-weight: 14px;
  margin-bottom: 0;
}

.product-filter-btn {
  display: inline-block;
  font-size: 14px;
  color: var(--clr-common-white);
  background: var(--clr-common-black);
  padding: 0 25px;
  height: 35px;
  line-height: 35px;
  text-transform: uppercase;
  margin-right: 30px;
}
.product-filter-btn-4 {
  margin-right: 0;
  margin-left: 30px;
}
.product-filter-btn i {
  padding-right: 3px;
}
.product-filter-btn:hover {
  background: var(--clr-theme-1);
}

.product-details-section-title h3 {
  font-size: 14px;
  text-transform: uppercase;
}

.product-line {
  height: 1px;
  border-bottom: 1px solid #ebebeb;
}

/* 16. Cart */
.table-content table {
  background: #ffffff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #eaedff;
}

.table-content .product-quantity {
  float: none;
}
.table-content .product-quantity .cart-plus-minus {
  position: relative;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: #f57e60;
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th, .table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}

.product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #dcdcdc;
  margin: 0 2px;
}

.table td, .table th {
  border-top: 1px solid #eaedff;
}

.product-quantity > input {
  width: 80px;
  border-radius: 3px;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 50px;
  border: 2px solid #eaedff;
  padding: 0 15px;
  margin-right: 10px;
}
#coupon_code::placeholder {
  color: #999999;
}
#coupon_code:focus {
  border-color: #222;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #eaedff;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

/* 17. Checkout */
.coupon-accordion h3 {
  background-color: #f6f6f6;
  border-top: 3px solid #ebebeb;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}

.coupon-accordion span:hover, p.lost-password a:hover {
  color: #222;
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  box-shadow: none;
}
.coupon-info p.form-row-first input:focus, .coupon-info p.form-row-last input:focus {
  border-color: #222;
}

.coupon-info p.form-row input[type=submit]:hover, p.checkout-coupon input[type=submit]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type=checkbox] {
  position: relative;
  top: 2px;
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input, .coupon input[type=submit], .buttons-cart a, .coupon-info p.form-row input[type=submit] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type=text] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
  outline: none;
  box-shadow: none;
}
p.checkout-coupon input[type=text]::placeholder {
  color: #999;
}
p.checkout-coupon input[type=text]:focus {
  border-color: #222;
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label, .checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required, .checkout-form-list label span.required {
  color: red;
}

.country-select .nice-select {
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type=text], .checkout-form-list input[type=password], .checkout-form-list input[type=email] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  box-shadow: none;
}
.checkout-form-list input[type=text]:focus, .checkout-form-list input[type=password]:focus, .checkout-form-list input[type=email]:focus {
  border-color: #222;
}

.checkout-form-list input[type=text]::-moz-placeholder,
.checkout-form-list input[type=password]::-moz-placeholder,
.checkout-form-list input[type=email]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=text]::placeholder,
.checkout-form-list input[type=password]::placeholder,
.checkout-form-list input[type=email]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=checkbox] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: #6f7172;
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 90px;
  padding: 15px;
  width: 100%;
  resize: none;
}
.order-notes textarea:focus {
  border-color: #222;
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid #eaedff;
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th, .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #ebebeb;
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
}
.payment-method .accordion-button:not(.collapsed) {
  color: #222;
  background-color: #fff;
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
}
.payment-method .accordion-collapse {
  border: none;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}

.order-button-payment button {
  width: 100%;
}

.single-service-2 {
  padding: 0px 68px;
  position: relative;
}
@media (max-width: 575px) {
  .single-service-2 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-service-2 {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-service-2 {
    padding: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-service-2 {
    padding: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .single-service-2 {
    padding: 0px 38px;
  }
}
.single-service-2 .thumb {
  margin-bottom: 45px;
}
.single-service-2 .content h5 {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 20px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-service-2 .content h5 {
    font-size: 13px;
  }
}
.single-service-2 .content h5 a {
  text-decoration: none;
  color: #222222;
}
.single-service-2 .content h5 a:hover {
  color: #f67174;
}
.single-service-2 .content p {
  color: #666666;
  line-height: 24px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-service-2 .content p {
    font-size: 13px;
  }
}
.single-service-2 .shape-line {
  position: absolute;
  right: -121px;
  top: 36px;
}
.single-service-2 .shape-line.pos-top {
  top: 0;
}

.service-wrapper {
  border-bottom: 1px solid #ebebeb;
}

.service-shapes .shape-wrapper.wrap1 {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 141px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service-shapes .shape-wrapper.wrap1 {
    top: 88px;
  }
}
.service-shapes .shape-wrapper.wrap2 {
  z-index: -1;
  position: absolute;
  top: 92px;
  right: 0;
}
.service-shapes .shape-wrapper.wrap3 {
  z-index: -1;
  position: absolute;
  top: -8px;
  left: 42.65%;
  transform: translateX(-50%);
}
.service-shapes .shape-wrapper.wrap4 {
  z-index: -1;
  position: absolute;
  right: 427px;
  bottom: -52px;
}

.l-deal-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: #f67174;
  margin-bottom: 18px;
  display: block;
}

.l-deal-title {
  font-size: 60px;
  color: #222222;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 33px;
}
@media (max-width: 575px) {
  .l-deal-title {
    font-size: 30px;
  }
}
.l-deal-title span {
  font-weight: 600;
  color: #f67174;
}

.l-deal-desc {
  font-size: 16px;
  color: #222222;
  margin-bottom: 0;
  line-height: 1.875;
}

.deal-title-area {
  border-bottom: 1px solid #ebebeb;
}

.ending-countdown .countdown-inner ul li {
  display: inline-block;
  font-size: 12px;
  color: #999999;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 15px;
}
.ending-countdown .countdown-inner ul li span {
  font-weight: 500;
  font-size: 36px;
  color: #272727;
  display: inline-block;
  margin-right: 3px;
}

.ending-d-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #f67174;
}

.deal-img-space img.deal-img-main {
  margin-left: -62px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .deal-img-space img.deal-img-main {
    max-width: 500px;
  }
}

.l-shape-wrapper.wrap-1 {
  position: absolute;
  left: -251px;
  top: 85px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .l-shape-wrapper.wrap-1 {
    left: -151px;
  }
}
.l-shape-wrapper.wrap-2 {
  position: absolute;
  right: 109px;
  top: -118px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-shape-wrapper.wrap-2 {
    top: -24px;
    right: 160px;
    z-index: -1;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .l-shape-wrapper.wrap-2 {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .l-shape-wrapper.wrap-2 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-shape-wrapper.wrap-2 img {
    max-width: 150px;
  }
}
.l-shape-wrapper.wrap-3 {
  position: absolute;
  top: 12px;
  left: 92px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-shape-wrapper.wrap-3 {
    z-index: -1;
    left: 36px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .l-shape-wrapper.wrap-3 {
    left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-shape-wrapper.wrap-3 img {
    max-width: 150px;
  }
}
.l-shape-wrapper.wrap-4 {
  position: absolute;
  right: 273px;
  top: 150px;
}
.l-shape-wrapper.wrap-5 {
  position: absolute;
  bottom: -52px;
  left: 183px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-shape-wrapper.wrap-5 {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .l-shape-wrapper.wrap-5 {
    left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-shape-wrapper.wrap-5 img {
    max-width: 375px;
  }
}

@media (max-width: 575px) {
  .l-area-padding {
    padding-right: 0;
    margin-bottom: 90px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .l-area-padding {
    padding-right: 0;
    margin-bottom: 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .l-area-padding {
    padding-right: 0;
    margin-bottom: 90px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .l-area-padding {
    margin-bottom: 90px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .l-area-padding {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .deal-img-space-5 {
    margin-left: 70px;
  }
}

.breadcrumb-title h2 {
  font-size: 80px;
  line-height: 1;
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title h2 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-title h2 {
    font-size: 65px;
  }
}
@media (max-width: 575px) {
  .breadcrumb-title h2 {
    font-size: 65px;
  }
}

.pt-255 {
  padding-top: 255px;
}

.pb-265 {
  padding-bottom: 265px;
}

.breadcrumb-area {
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area {
    padding-top: 70px;
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area {
    padding-top: 130px;
    padding-bottom: 180px;
  }
}
@media (max-width: 575px) {
  .breadcrumb-area {
    padding-top: 130px;
    padding-bottom: 180px;
  }
}

.breadcrumb-list {
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-list {
    bottom: 20px;
  }
}
@media (max-width: 575px) {
  .breadcrumb-list {
    left: 0;
    transform: translateX(0);
    right: 0;
    text-align: center;
  }
}
.breadcrumb-list a, .breadcrumb-list span {
  font-size: 13px;
  line-height: 13.99px;
  text-decoration: none;
  color: #999999;
  position: relative;
  margin: 0 3.5px;
  display: inline-block;
}
.breadcrumb-list a:hover {
  color: var(--clr-theme-sub-2);
}
.breadcrumb-list a::after {
  content: "/";
  color: #999999;
  transform: translateX(5px);
  display: inline-block;
}
.breadcrumb-list span {
  color: #222222;
}

.breadcrumb-list-2 {
  padding: 30px 0;
}
.breadcrumb-list-2 .breadcrumb {
  margin-bottom: 0;
}
.breadcrumb-list-2 .breadcrumb-item {
  font-size: 13px;
  line-height: 13.99px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  margin: 0 3.5px;
  color: #999999;
}
.breadcrumb-list-2 .breadcrumb-item.active, .breadcrumb-list-2 .breadcrumb-item:hover {
  color: #222;
}
.breadcrumb-list-2 .breadcrumb-item + .breadcrumb-item::before {
  color: #999999;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area-2 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area-2 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area-2 {
    padding-top: 140px;
    padding-bottom: 120px;
  }
}
@media (max-width: 575px) {
  .breadcrumb-area-2 {
    padding-top: 140px;
    padding-bottom: 120px;
  }
}

.breadcrumb-wrapper-2 h3 {
  font-size: 80px;
  font-weight: 400;
  margin-bottom: 15px;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .breadcrumb-wrapper-2 h3 {
    font-size: 60px;
  }
}
.breadcrumb-wrapper-2 .breadcrumb-item {
  font-size: 13px;
  color: #999999;
  text-transform: capitalize;
}
.breadcrumb-wrapper-2 .breadcrumb-item.active {
  color: #222;
}

.single-widget-category:not(:last-child) {
  margin-bottom: 15px;
}
.single-widget-category input {
  display: none;
}
.single-widget-category label {
  color: #666666;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  padding-left: 35px;
}
.single-widget-category label::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
}
.single-widget-category label::after {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font awesome 5 pro";
  color: var(--clr-theme-1);
  font-size: 12px;
  content: "\f00c";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 200;
}
.single-widget-category input:checked + label {
  color: var(--clr-theme-1);
}
.single-widget-category input:checked + label::after {
  opacity: 1;
  visibility: visible;
}
.single-widget-category label span {
  color: #bab9b9;
}

.widget-title {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 25px;
}

.single-widget {
  border-bottom: 1px solid #ebebeb;
}

.title-price-space {
  margin-bottom: 33px;
}

.scroll-box-default {
  max-height: 200px;
  overflow-y: auto;
}

.single-widget-category .color-black-bg::before {
  background-color: #222;
  border-color: transparent;
}
.single-widget-category .color-green-bg::before {
  background-color: #309232;
  border-color: transparent;
}
.single-widget-category .color-blue-bg::before {
  background-color: #2237d6;
  border-color: transparent;
}
.single-widget-category .color-red-bg::before {
  background-color: #e21515;
  border-color: transparent;
}
.single-widget-category .color-gray-bg::before {
  background-color: #777;
  border-color: transparent;
}
.single-widget-category .color-black-bg::before {
  background-color: #222;
  border-color: transparent;
}
.single-widget-category .color-white-bg::before {
  background-color: #ffffff;
  border-color: #ddd;
}

.widget-color-box input:checked + label::after {
  color: var(--clr-common-white);
}
.widget-color-box input.white-input:checked + label::after {
  color: var(--clr-theme-1);
}

.scroll-box-default::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
}
.scroll-box-default::-webkit-scrollbar-track {
  background: #ebebeb;
}
.scroll-box-default::-webkit-scrollbar-thumb {
  background-color: #ebebeb;
  border-radius: 2px;
  border: 3px solid #b7b7b7;
}

.tagcloud a {
  background: var(--clr-common-white);
  border: 1px solid #ebebeb;
  display: inline-block;
  color: #a3a3a3;
  text-decoration: none;
  height: 26px;
  padding: 0 20px;
  font-size: 12px;
  margin-right: 7px;
  margin-bottom: 7px;
  line-height: 26px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tagcloud a {
    padding: 0 10px;
  }
}
.tagcloud a:hover {
  color: var(--clr-common-white);
  background-color: #222;
  border-color: transparent;
}

/*range slider css start*/
#slider-range {
  position: relative;
}

.ui-widget.ui-widget-content {
  border: none;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.004);
}

.ui-slider-horizontal {
  height: 3px;
}

.ui-widget-content {
  background: #ebebeb;
}

.ui-widget-header {
  background: #222;
}

.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  transform: translateY(-50%);
}

.ui-slider .ui-slider-handle {
  height: 17px;
  width: 3px;
  background: #222222;
  margin-left: 0;
  border-radius: 0;
}

.slider-range p {
  color: #666666;
  margin-bottom: 0;
}

.slider-range p label {
  font-size: 12px;
  font-weight: 400;
  color: #6b6b6b;
}

.slider-range p input {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
}

.slider-range p label {
  margin-right: 3px;
}

.slider-range input {
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  color: #6b6b6b;
  border: none;
  outline: none;
  background: none;
  width: 100px;
}

.shop-top-left > span {
  font-size: 14px;
  color: #666666;
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .shop-top-left > span {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .shop-top-left-2 {
    margin-bottom: 15px;
  }
}
@media (max-width: 575px) {
  .shop-top-left-3 > span {
    margin-top: 15px;
    display: block;
  }
}
.shop-top-right .select-default .list {
  width: 100%;
}
.shop-top-right-4 .select-default .nice-select {
  padding-right: 15px;
}

.tab-btn-group button {
  padding: 0;
  line-height: 1;
  background: transparent;
  color: #acacac;
  border: 0;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 10px;
}
.tab-btn-group button.active {
  color: #222;
}

.show-total-result {
  font-size: 14px;
  color: #666666;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .show-total-result {
    margin-bottom: 15px;
  }
}
.show-total-result-4 {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .show-total-result-4 {
    margin-bottom: 15px;
  }
}
.show-total-result-5 {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .show-total-result-5 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.select-default .nice-select {
  border: 0;
  float: none;
  height: 23px;
  line-height: 23px;
  padding: 0;
  max-width: 168px;
  margin-left: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .select-default .nice-select {
    margin-left: auto;
  }
}
@media (max-width: 575px) {
  .select-default .nice-select {
    margin-right: auto;
    margin-left: inherit;
  }
}
.select-default .nice-select::after {
  right: 0;
  color: #666;
}
.select-default .nice-select span {
  color: #666;
  font-size: 14px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .select-default-2 .nice-select {
    margin-left: inherit !important;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .select-default-3 .nice-select {
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .select-default-4 .nice-select {
    margin: 0;
    margin-left: auto;
  }
}

.product-thumb-list {
  max-width: 260px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product-thumb-list {
    max-width: inherit;
    margin-right: 0;
  }
}

.puik-list-product-wrap {
  display: flex;
  align-items: center;
}

.puik-product-list-content {
  margin-left: -15px;
  padding-right: 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .puik-product-list-content {
    margin-left: 0;
    margin-top: 25px;
  }
}
.puik-product-list-content p {
  color: #666666;
  line-height: 30px;
  margin-bottom: 22px;
}
.puik-product-list-content .pro-title {
  margin-bottom: 6px;
  padding-bottom: 0;
}
.puik-product-list-content .rating {
  margin-bottom: 15px;
}

.puik-cart-btn {
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #f6f6f6;
  height: 43px;
  line-height: 41px;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #222222;
  text-transform: uppercase;
  padding: 0 55px;
}
.puik-cart-btn:hover {
  background-color: var(--clr-common-black);
  color: var(--clr-common-white);
  border-color: transparent;
}

.puik-proudct-btn-boxed {
  display: inline-block;
  border-radius: 2px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border: 1px solid #e1e1e1;
  vertical-align: middle;
  font-size: 15px;
}
.puik-proudct-btn-boxed:hover {
  background-color: var(--clr-common-black);
  color: var(--clr-common-white);
  border-color: transparent;
}

.puik-shop-product-actions a {
  margin-right: 6px;
}
@media (max-width: 575px) {
  .puik-shop-product-actions a {
    margin-bottom: 6px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-area {
    padding-right: 0;
    margin-top: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-sidebar-area {
    padding-right: 0;
    margin-top: 50px;
  }
}
@media (max-width: 575px) {
  .shop-sidebar-area {
    padding-right: 0;
    margin-top: 50px;
  }
}

@media (max-width: 575px) {
  .shop-top-area {
    margin-bottom: 30px;
  }
}

.single-product-6-wrapper {
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}

.single-product-6 {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f6f6f6;
}
.single-product-6 .product-content {
  padding-left: 35px;
}

@media only screen and (min-width: 576px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-range {
    width: 50%;
  }
}

.product__modal-close button {
  font-size: 30px;
  color: var(--clr-common-black);
  background: transparent;
  border: 0;
  outline: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}
.product__modal-area .modal-dialog {
  max-width: 900px;
}
.product__modal-inner {
  padding: 30px;
  display: flex;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__modal-inner {
    display: block;
  }
}
.product__modal-left {
  width: 80%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__modal-left {
    width: 100%;
  }
}
.product__modal-left .nav-tabs {
  border: 0;
  flex-wrap: inherit;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__modal-left .nav-tabs {
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
.product__modal-left .nav-tabs .nav-item .nav-link {
  border: 0;
  padding: 0;
}
.product__modal-right {
  padding-left: 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__modal-right {
    padding-left: 0;
  }
}
.product__modal-title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 7px;
}
.product__modal-rating {
  margin-bottom: 7px;
}
.product__modal-rating ul li {
  display: inline-block;
}
.product__modal-rating ul li a {
  font-size: 13px;
  color: var(--clr-common-orange);
}
.product__modal-price .price {
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  color: var(--clr-soft-gray-2);
}
.product__modal-price .price.new-price {
  color: var(--clr-theme-1);
}
.product__modal-price .price.old-price {
  font-size: 20px;
  text-decoration: line-through;
}
.product__modal-available p {
  font-size: 14px;
  color: var(--clr-common-black);
  margin-bottom: 0;
}
.product__modal-available p span {
  color: var(--clr-theme-green);
}
.product__modal-sku p {
  font-size: 14px;
  color: var(--clr-common-black);
}
.product__modal-sku p span {
  color: var(--clr-soft-gray-7);
}
.product__modal-des p {
  color: var(--clr-soft-gray-7);
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .product__modal-quantity .product-quantity {
    margin-right: 0;
  }
}
.product__modal-quantity h5 {
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.product__modal-quantity .cart-plus-minus input {
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  padding: 0 20px;
}
.product__modal-quantity .cart-plus-minus {
  width: 90px;
}
@media (max-width: 575px) {
  .product__modal-quantity .cart-plus-minus {
    width: 100%;
  }
}
.product__modal-categories {
  margin-bottom: 7px;
}
.product__modal-categories span {
  color: var(--clr-common-black);
  font-size: 14px;
}
.product__modal-categories ul {
  margin-left: 5px;
}
@media (max-width: 575px) {
  .product__modal-categories ul {
    margin-left: 0;
  }
}
.product__modal-categories ul li {
  display: inline-block;
}
.product__modal-categories ul li a {
  font-size: 14px;
  color: var(--clr-soft-gray-7);
}
.product__modal-share {
  margin-top: 13px;
}
.product__modal-share span {
  font-size: 14px;
  color: var(--clr-common-black);
  margin-right: 10px;
}
@media (max-width: 575px) {
  .product__modal-share span {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.product__modal-share ul li {
  display: inline-block;
  margin-right: 5px;
}
.product__modal-share ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 13px;
  border: 1px solid var(--clr-soft-gray-5);
  text-align: center;
  border-radius: 50%;
  color: var(--clr-soft-gray-7);
}
.product__modal-share ul li a:hover {
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
  border-color: var(--clr-common-black);
}

.customer-review a {
  font-size: 14px;
  color: var(--clr-soft-gray-2);
}

.product-modal-cart-btn {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--clr-common-white);
  background-color: var(--clr-common-black);
  height: 50px;
  line-height: 47px;
  border: 1px solid var(--clr-common-black);
  padding: 0 65px;
  outline: none;
}
.product-modal-cart-btn:hover {
  background-color: var(--clr-common-white);
  color: var(--clr-common-black);
}

.cart-plus-minus {
  width: 180px;
}

.cart-plus-minus input {
  border: 0px;
  outline: 0px;
  background: none;
  font-weight: 400;
  color: var(--clr-common-black);
  font-size: 14px;
  display: inline-block;
  height: 45px;
  list-style: 45px;
  padding: 0 50px;
  width: 100%;
  border: 1px solid #ebebeb;
  text-align: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.cart-plus-minus .qtybutton {
  font-size: 20px;
  color: var(--clr-common-black);
  display: inline-block;
  position: absolute;
  top: 50%;
  height: 22px;
  width: 22px;
  background: transparent;
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cart-plus-minus .dec {
  left: 7px;
}

.cart-plus-minus .inc {
  right: 7px;
}

.product-quantity {
  position: relative;
}

.search__area {
  position: fixed;
  z-index: 999999;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: var(--clr-common-white);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.51, 0.92, 0.24, 1.15);
}
.search__area.search-active {
  visibility: visible;
  opacity: 1;
  top: 0;
  bottom: auto;
  height: 100%;
}
.search__wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 70%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.search__wrapper h4 {
  text-align: center;
  font-size: 30px;
}
@media (max-width: 575px) {
  .search__wrapper {
    width: 90%;
  }
}
.search__close {
  position: absolute;
  top: 60px;
  right: 60px;
}
@media (max-width: 575px) {
  .search__close {
    top: 20px;
    right: 20px;
  }
}
.search__close-btn {
  font-size: 30px;
  color: var(--clr-common-black);
  background: transparent;
  border: 0;
  outline: 0;
}
.search__input {
  position: relative;
}
.search__input input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 50px;
  padding-right: 20px;
  border: 0;
  border-bottom: 1px solid var(--clr-common-black);
}
.search__input input::placeholder {
  color: #999;
  font-size: 14px;
}
.search__input button {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: transparent;
  color: var(--clr-common-black);
  border: 0;
  outline: 0;
  font-size: 24px;
}

.cartmini__area .modal.show .modal-dialog {
  transform: none !important;
}
.cartmini__area .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(100px, 0px);
}
.cartmini__area .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--clr-common-white);
}
.cartmini__area .modal-content {
  border: 0;
}
.cartmini__wrapper {
  padding: 65px 65px;
  padding-top: 50px;
}
@media (max-width: 575px) {
  .cartmini__wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.cartmini__top {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--clr-soft-gray-5);
}
.cartmini__top h4 {
  font-size: 18px;
  margin-bottom: 0;
}
.cartmini__close-btn {
  font-size: 24px;
  color: var(--clr-common-black);
  background: transparent;
  border: 0;
  outline: 0;
}
.cartmini__item {
  padding: 20px 0;
  padding-right: 30px;
  border-bottom: 1px solid var(--clr-soft-gray-5);
}
.cartmini__item:last-child {
  border: none;
}
.cartmini__thumb img {
  width: 80px;
}
.cartmini__title {
  font-size: 15px;
  color: var(--clr-common-black);
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;
}
.cartmini__title a:hover {
  color: var(--clr-theme-1);
}
.cartmini__remove {
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 13px;
  color: #bebdbd;
  height: 17px;
  width: 17px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #bebdbd;
  border-radius: 50%;
}
.cartmini__price .price {
  font-size: 13px;
  color: var(--clr-common-black);
}
.cartmini__total {
  padding: 20px 0;
  margin-bottom: 20px;
  border-top: 1px solid var(--clr-soft-gray-5);
}
.cartmini__total h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
  color: var(--clr-soft-gray-2);
}
.cartmini__total span {
  font-size: 18px;
  color: var(--clr-common-black);
}
.cartmini__list {
  height: 575px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cartmini__list {
    height: 540px;
  }
}
@media (max-width: 575px) {
  .cartmini__list {
    height: 475px;
  }
}

.features__title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.features__title-2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.features__icon {
  min-height: 60px;
}
.features__icon-2 {
  margin-bottom: 25px;
}
.features__item {
  padding: 0 92px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .features__item {
    padding-left: 52px;
    padding-right: 52px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features__item {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .features__item {
    padding-left: 0;
    padding-right: 0;
  }
}
.features__item-br {
  border-right: 1px solid #ebebeb;
}
.features__item-bb {
  border-bottom: 1px solid #ebebeb;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .features__item-bb {
    padding-top: 40px;
  }
}
.features__item-2 {
  padding-left: 45px;
  padding-right: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .features__item-2 {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 575px) {
  .features__item-2 {
    padding-left: 0;
    padding-right: 0;
  }
}
.features__item-pt-65 {
  padding-top: 65px;
}
.features__item-pb-40 {
  padding-bottom: 40px;
}
.features__content-2 p {
  color: #555555;
}

.about__title {
  font-size: 80px;
  color: #222;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .about__title {
    font-size: 50px;
  }
}
.about__title-pre {
  font-size: 16px;
  color: #666666;
  display: inline-block;
  margin-bottom: 5px;
}
.about__title-area p {
  font-size: 16px;
  color: #666666;
  padding: 0 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about__title-area p {
    padding: 0 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about__title-area p {
    padding: 0;
  }
}
.about__history-title {
  font-size: 36px;
  padding-left: 125px;
  margin-bottom: 40px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__history-title {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .about__history-title {
    font-size: 20px;
    padding-left: 0;
  }
}
.about__history-title span {
  font-weight: 400;
}
.about__history-title-pre {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--clr-theme-sub-2);
  position: relative;
  padding-left: 125px;
  margin-bottom: 13px;
  display: inline-block;
}
@media (max-width: 575px) {
  .about__history-title-pre {
    padding-left: 0;
  }
}
.about__history-title-pre::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 100px;
  height: 2px;
  transform: translateY(-50%);
  background: var(--clr-theme-sub-2);
}
@media (max-width: 575px) {
  .about__history-title-pre::after {
    display: none;
  }
}
.about__history-wrapper p {
  font-size: 14px;
  color: #555555;
}
.about__history-wrapper p.about__history-text {
  font-size: 16px;
  color: #222;
  margin-bottom: 30px;
}
.about__gallery-review {
  padding: 155px 200px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about__gallery-review {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__gallery-review {
    padding: 108px 115px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__gallery-review {
    padding: 65px 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__gallery-review {
    padding: 70px 55px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__gallery-review {
    padding: 70px 75px;
  }
}
@media (max-width: 575px) {
  .about__gallery-review {
    padding: 50px 20px;
  }
}
.about__gallery-review h3 {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5px;
}
.about__gallery-review span {
  font-size: 12px;
  font-weight: 500;
  color: #555555;
  letter-spacing: 12px;
  display: inline-block;
  margin-bottom: 25px;
}
.about__gallery-review p {
  color: #b0b0b0;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .about__offer {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
.about__offer-content h3 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .about__offer-content h3 {
    font-size: 20px;
  }
}
.about__offer-content p {
  font-size: 14px;
  color: #fff;
  padding: 0 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about__offer-content p {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__offer-content p {
    padding-right: 90px;
    padding-left: 90px;
  }
}
@media (max-width: 575px) {
  .about__offer-content p {
    padding-right: 0;
    padding-left: 0;
  }
}
.about__me-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}
.about__me-wrapper {
  padding-left: 150px;
  padding-right: 145px;
  padding-top: 270px;
  padding-bottom: 260px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about__me-wrapper {
    padding-left: 70px;
    padding-right: 115px;
    padding-top: 190px;
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__me-wrapper {
    padding-left: 30px;
    padding-right: 45px;
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__me-wrapper {
    padding-left: 50px;
    padding-right: 45px;
    padding-top: 130px;
    padding-bottom: 225px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__me-wrapper {
    padding-left: 25px;
    padding-right: 95px;
    padding-top: 115px;
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__me-wrapper {
    padding-left: 25px;
    padding-right: 35px;
    padding-top: 130px;
    padding-bottom: 110px;
  }
}
@media (max-width: 575px) {
  .about__me-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 145px;
    padding-bottom: 130px;
  }
}
.about__me-title {
  font-size: 80px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .about__me-title {
    font-size: 55px;
  }
}
.about__me-title-pre {
  font-size: 14px;
  color: #555555;
  display: inline-block;
  margin-bottom: 5px;
}
.about__me-content p {
  font-size: 14px;
  color: #555555;
  margin-bottom: 20px;
}
.about__me-contact ul li {
  font-size: 14px;
  color: #222222;
  margin-bottom: 2px;
}
.about__me-contact ul li a:hover {
  color: var(--clr-theme-1);
}
.about__me-social ul li {
  display: inline-block;
  margin-right: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
  .about__me-social ul li {
    margin-bottom: 10px;
  }
}
.about__me-social ul li a {
  color: #fff;
  background-color: #222;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  min-width: 120px;
  font-size: 14px;
  text-align: center;
}
.about__me-social ul li a.fb {
  background-color: #3c5b9b;
}
.about__me-social ul li a.fb:hover {
  background-color: #222;
}
.about__me-social ul li a.tw {
  background-color: #40c1df;
}
.about__me-social ul li a.tw:hover {
  background-color: #222;
}
.about__me-social ul li a.gp {
  background-color: #ed3c32;
}
.about__me-social ul li a.gp:hover {
  background-color: #222;
}
.about__me-social ul li a.dri {
  background-color: #d03384;
}
.about__me-social ul li a.dri:hover {
  background-color: #222;
}

.team__thumb img {
  width: 200px;
  height: 200px;
}
.team__item-2 {
  padding: 0 60px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .team__item-2 {
    padding: 0 55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__item-2 {
    padding: 0 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__item-2 {
    padding: 0 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .team__item-2 {
    padding: 0;
  }
}
.team__title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.team__position {
  font-size: 14px;
  color: #999999;
  display: inline-block;
  margin-bottom: 20px;
}
.team__section-title > span {
  font-size: 14px;
  font-weight: 700;
  color: #f57e60;
  text-transform: uppercase;
  display: inline-block;
}
.team__section-title h3 {
  font-size: 36px;
}
@media (max-width: 575px) {
  .team__section-title h3 {
    font-size: 28px;
  }
}
.team__section-title h3 span {
  font-weight: 400;
}
.team__section-title p {
  padding: 0 90px;
  color: #555555;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__section-title p {
    padding: 0 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__section-title p {
    padding: 0 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__section-title p {
    padding: 0 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__section-title p {
    padding: 0 15px;
  }
}
@media (max-width: 575px) {
  .team__section-title p {
    padding: 0;
  }
}
.team__social ul li {
  display: inline-block;
  margin: 0 5px;
}
.team__social ul li a {
  display: inline-block;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #999;
  border: 1px solid #ececec;
  border-radius: 50%;
}
.team__social ul li a:hover {
  color: #ffffff;
  background: #222;
}
.team__social ul li a.fb:hover {
  background: #3c5b9b;
  border-color: #3c5b9b;
}
.team__social ul li a.tw:hover {
  background: #40c1df;
  border-color: #40c1df;
}
.team__social ul li a.dri:hover {
  background: #c72982;
  border-color: #c72982;
}
.team__social ul li a.gp:hover {
  background: #de1e1e;
  border-color: #de1e1e;
}
.team__hero-title span {
  font-size: 14px;
  font-weight: 700;
  color: #f57e60;
  display: inline-block;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.team__hero-title h3 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 45px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .team__hero-wrapper {
    padding-right: 0;
  }
}
.team__hero-wrapper p {
  font-size: 14px;
  color: #555555;
  margin-bottom: 30px;
}
.team__hero-sign h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}
.team__hero-sign span {
  font-size: 14px;
  color: #999999;
  display: block;
  line-height: 1;
  margin-bottom: 30px;
}

.apply__item-border {
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .apply__item-border {
    border-right: 0;
  }
}
@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .apply__item-border {
    border: 0;
  }
}
.apply__title span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-bottom: 5px;
}
.apply__title h3 {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 25px;
}
.apply__content {
  padding: 162px 100px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .apply__content {
    padding: 82px 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .apply__content {
    padding: 35px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .apply__content {
    padding: 95px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .apply__content {
    padding: 27px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .apply__content {
    padding: 80px 85px;
  }
}
@media (max-width: 575px) {
  .apply__content {
    padding: 65px 10px;
  }
}
.apply__content p {
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  margin-bottom: 50px;
}
.apply__btn {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  border: 2px solid #fff;
  height: 50px;
  line-height: 48px;
  text-align: center;
  padding: 0 52px;
  background: transparent;
}
.apply__btn:hover {
  color: #222;
  background: #fff;
}

.page__title {
  font-size: 80px;
  color: #222;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .page__title {
    font-size: 50px;
  }
}
.page__title-pre {
  font-size: 16px;
  color: #666666;
  display: inline-block;
  margin-bottom: 5px;
}
.page__title-wrapper p {
  font-size: 16px;
  color: #666666;
  padding: 0 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .page__title-wrapper p {
    padding: 0 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .page__title-wrapper p {
    padding: 0;
  }
}

.contact__map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.contact__title > span {
  font-size: 14px;
  font-weight: 700;
  color: var(--clr-theme-sub-2);
  text-transform: uppercase;
  display: inline-block;
}
.contact__title h3 {
  font-size: 36px;
}
@media (max-width: 575px) {
  .contact__title h3 {
    font-size: 28px;
  }
}
.contact__title h3 span {
  font-weight: 400;
}
.contact__title p {
  padding: 0 90px;
  color: #555555;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__title p {
    padding: 0 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__title p {
    padding: 0 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__title p {
    padding: 0 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact__title p {
    padding: 0 15px;
  }
}
@media (max-width: 575px) {
  .contact__title p {
    padding: 0;
  }
}
.contact__form {
  position: relative;
  z-index: 1;
  padding: 95px 100px 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .contact__form {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 575px) {
  .contact__form {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.contact__form-title h3 {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
}
.contact__form-title p {
  font-size: 14px;
  color: #999999;
  margin-bottom: 20px;
}
.contact__form-input input, .contact__form-input textarea {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  color: #fff;
  border: 1px solid #393939;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #282828;
}
.contact__form-input input::placeholder, .contact__form-input input::-moz-placeholder, .contact__form-input textarea::placeholder, .contact__form-input textarea::-moz-placeholder {
  color: #666666;
}
.contact__form-input input:focus, .contact__form-input textarea:focus {
  border-color: #f57e60;
}
.contact__form-input textarea {
  height: 150px;
  resize: none;
  line-height: 1.2;
  padding: 20px;
}
.contact__form-btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  text-align: center;
  background-color: var(--clr-theme-sub-2);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.contact__form-btn:hover {
  background-color: #fff;
  color: #f57e60;
}
.contact__address-item {
  margin-bottom: 30px;
}
.contact__address-flag {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}
.contact__address-flag .contact-flag-content p {
  font-size: 12px;
  margin-bottom: 0;
  color: #999999;
  line-height: 1;
}
.contact__address-flag .contact-flag-content h5 {
  font-size: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.contact__address-content {
  padding-top: 30px;
}
.contact__address-content ul li {
  color: #555555;
  font-size: 14px;
}
.contact__address-content ul li a:hover {
  color: #f57e60;
}

/*----------------------------------------*/
/*  ERROR CSS START
/*----------------------------------------*/
.error__pt-300 {
  padding-top: 300px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .error__pt-300 {
    padding-top: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error__pt-300 {
    padding-top: 160px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .error__pt-300 {
    padding-top: 125px;
  }
}
.error__pb-315 {
  padding-bottom: 315px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .error__pb-315 {
    padding-bottom: 215px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error__pb-315 {
    padding-bottom: 185px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .error__pb-315 {
    padding-bottom: 140px;
  }
}
.error__content > span {
  font-size: 16px;
  color: #222;
}
.error__content p {
  margin-bottom: 55px;
  font-size: 16px;
  color: #222;
}
.error__content .highlight {
  color: #f57e60;
}
@media (max-width: 575px) {
  .error__content .s-btn-big {
    padding-left: 55px;
    padding-right: 55px;
  }
}
.error__number h1 {
  font-size: 160px;
  color: #222222;
  line-height: 1;
  margin-bottom: 15px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error__number h1 {
    font-size: 200px;
  }
}
@media (max-width: 575px) {
  .error__number h1 {
    font-size: 100px;
  }
}
.error__search input {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #393939;
  margin-bottom: 50px;
  outline: none;
}
.error__search input::placeholder {
  color: #8c8c8c;
}
.error__search input:focus {
  border-color: #f57e60;
}

.faq__wrapper {
  padding-right: 18px;
}
.faq__wrapper-right {
  padding-left: 18px;
  padding-right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq__wrapper-right {
    padding-left: 0;
    margin-top: 50px;
  }
}
.faq__title {
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 35px;
}
.faq__title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #222;
}
.faq__accordion .accordion-item {
  margin-bottom: 25px;
}
.faq__accordion .accordion-button {
  font-size: 14px;
  text-transform: capitalize;
  color: #222;
  font-weight: 600;
  padding: 0;
  border: 0;
  position: relative;
  box-shadow: none;
  background: #fff;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  padding-right: 30px;
}
.faq__accordion .accordion-button::after {
  position: absolute;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  content: "\f068";
  font-family: "Font Awesome 5 Pro";
  background: none;
  font-size: 14px;
  color: #222;
  font-weight: 400;
  height: inherit;
}
.faq__accordion .accordion-button.collapsed {
  color: #222;
}
.faq__accordion .accordion-button.collapsed::after {
  content: "\f067";
}
.faq__accordion .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}
.faq__accordion .accordion-collapse {
  border: 0;
}
.faq__accordion .accordion-body {
  padding: 0;
}
.faq__accordion .product-description-wrapper p {
  color: #666666;
  line-height: 30px;
}

.testimonial__slider .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.testimonial__quote img {
  width: inherit !important;
  display: inline-block !important;
}
.testimonial__text p {
  color: #222;
  font-size: 16px;
  margin-bottom: 40px;
}
.testimonial__avater h4 {
  font-size: 14px;
  text-transform: uppercase;
}
.testimonial__avater span {
  font-size: 16px;
  color: #999999;
}

.counter__item h2 {
  font-size: 36px;
  font-weight: 400;
  color: #f57e60;
  margin-bottom: 0;
}
.counter__item p {
  font-size: 14px;
  font-weight: 700;
  color: #222;
}

.card__pr-30 {
  padding-right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .card__pr-30 {
    padding-right: 0;
    margin-bottom: 25px;
  }
}
.card__pl-40 {
  padding-left: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .card__pl-40 {
    padding-left: 0;
    margin-top: 25px;
  }
}
.card__img {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #f7f7f7;
  border-top-right-radius: 130px;
  border-bottom-left-radius: 130px;
}
.card__img img {
  box-shadow: 0px 25px 25px 0px rgba(203, 203, 203, 0.6);
  border-radius: 10px;
}
.card__content span {
  font-size: 20px;
  display: inline-block;
  margin-bottom: 13px;
}
.card__content h3 {
  font-size: 30px;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .card__content h3 {
    font-size: 24px;
  }
}
.card__content p {
  font-size: 14px;
  line-height: 30px;
  color: #555555;
  margin-bottom: 0;
}

/*----------------------------------------*/
/*  12. LOGIN CSS START
/*----------------------------------------*/
.basic-login {
  padding: 90px;
  border: 2px solid #eaedff;
}
.basic-login .s-btn {
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .basic-login {
    padding: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .basic-login {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .basic-login {
    padding: 30px;
  }
}
.basic-login h3 {
  font-size: 30px;
}

.basic-login input {
  width: 100%;
  height: 60px;
  border: 2px solid #eaedff;
  padding: 0 20px;
  margin-bottom: 20px;
  outline: none;
}

.basic-login input:focus {
  border-color: #222;
}

.basic-login input::placeholder,
.basic-login input::-moz-placeholder {
  color: #6f7172;
}

.basic-login label {
  color: #222;
  display: block;
}

.basic-login label span {
  color: red;
}

.login-action input {
  width: inherit;
  height: auto;
}

.login-action label {
  display: inline-block;
  margin-left: 5px;
}

.or-divide {
  border-top: 2px solid #eaedff;
  margin: 40px 0;
  text-align: center;
  position: relative;
}

.or-divide span {
  position: absolute;
  left: 0;
  right: 0;
  top: -9px;
  background: #ffffff;
  display: inline-block;
  width: 40px;
  margin: auto;
  line-height: 1;
  font-weight: 500;
}

@media (max-width: 767px) {
  .forgot-login {
    float: none;
  }
}
.forgot-login a {
  color: #222;
}

.forgot-login a:hover {
  color: #d4a533;
}

@media (max-width: 767px) {
  .log-rem {
    float: none;
    margin-bottom: 10px;
    display: block;
  }
}
/*-----------------------------------------------------------------------------------

    Template Name: Sample - Corporate Business Bootstrap4 HTML5 Template
    Template URI: site.com
    Description: Sample - Corporate Business Bootstrap4 HTML5 Template
    Author: BasicTheme
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/

/*# sourceMappingURL=main.css.map */


/* SCROLL TO TOP BUTTON */
#scroll_to_top {
  display: inline-block;
  background-color: #D4A533;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#scroll_to_top::after {
  content: "\f062";
  font-family: "Font Awesome 5 Pro";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  line-height: 40px;
  color: #fff;
}

#scroll_to_top:hover {
  cursor: pointer;
  background-color: #333;
}

#button:active {
  background-color: #555;
}

#scroll_to_top.show {
  opacity: 1;
  visibility: visible;
}

/* Styles for the content section */

@media (min-width: 500px) {
  #scroll_to_top {
    margin: 0px;
  }
}

/* SCROLL TO TOP BUTTON */

.form-control::-webkit-input-placeholder {
  color:lightgray !important;
  opacity:1;
}

.form-control::-moz-placeholder {
  color:lightgray !important;
  opacity:1;
}

.form-control::placeholder {
  color:lightgray !important;
  opacity:1;
}

::placeholder {
  color: lightgray !important;
  opacity: 1;
}

:-ms-input-placeholder {
 color: lightgray !important;
}

::-ms-input-placeholder {
 color: lightgray !important;
}

.font-italic {
  font-style: italic;
}