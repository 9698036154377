/*
.banner {
    background-image: url("../../images/cover.jfif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .banner > h1 {
    margin: 5vmax;
  
    font: 600 2.5vmax "Roboto";
  }
  
  .banner > p {
    font: 300 1.4vmax "Lucida Sans";
  }
  
  .banner > a > button {
    margin-bottom: 5vmax;
    cursor: pointer;
    background-color: white;
    border: 1px solid white;
    border-radius: 0;
    padding: 1vmax;
    transition: all 0.5s;
    width: 9vmax;
    font: 500 1vmax "Roboto";
  }
  .banner > a > button:hover {
    background-color: rgba(255, 255, 255, 0);
    color: white;
  }
  
  .banner::after {
    content: "";
    width: 100vw;
    height: 100vmin;
    background-color: #ffffff;
    position: absolute;
    top: 0%;
    left: 0;
    clip-path: polygon(100% 68%, 0 100%, 100% 100%);
    max-width: 100%;
  }
  
  .homeHeading {
    text-align: center;
    font-family: Roboto;
    font-size: 1.4vmax;
    border-bottom: 1px solid rgba(21, 21, 21, 0.5);
    width: 20vmax;
    padding: 1vmax;
    margin: 5vmax auto;
    color: rgb(0, 0, 0, 0.7);
  }
  
  .container {
    display: flex;
    margin: 2vmax auto;
    width: 80vw;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
  }
  
  .productCard {
    width: 14vmax;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: rgb(48, 48, 48);
    margin: 2vmax;
    transition: all 0.5s;
    padding-bottom: 0.5vmax;
  }
  
  .productCard > img {
    width: 14vmax;
  }
  
  .productCard > div {
    margin: 0.5vmax;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .productCardSpan {
    margin: 0.5vmax;
    font: 300 0.7vmax "Roboto";
  }
  
  .productCard > p {
    font-family: "Roboto";
    font-size: 1.2vmax;
    margin: 1vmax 0.5vmax;
    margin-bottom: 0;
  }
  
  .productCard > span {
    margin: 0.5vmax;
    color: tomato;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 1vmax;
  }
  
  .productCard:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
  
    transform: translateY(-1vmax);
  }
  
  @media screen and (max-width: 600px) {
    .productCard > p {
      font-size: 1.7vmax;
    }
  
    .productCard > div {
      margin: 0vmax;
      display: block;
    }
  
    .productCard > span {
      font-size: 1.5vmax;
    }
  
    .productCard > div > span {
      margin: 0 0.5vmax;
      font: 300 1vmax "Roboto";
    }
  }

  */

/* NIZAR CODE */

#slides{
  position: relative;
  height: 600px;
  padding:0px;
  margin:0px;
  list-style-type: none;
}

.slide{
  position: absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  opacity:0;
  z-index:1;
  
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}

.showing{
  opacity: 1;
  z-index: 2;
}

.slide{
  box-sizing: border-box;
  background: #000000;
  color: #ffffff;
}

.slide:nth-of-type(1){
  background-image: url("../../assets/img/slider/slider-02.jpg");
  background-size: cover;
  background-position: center;
}

/* Used to make the banner image responsive */
@media (max-width: 426px) {
  .slide:nth-of-type(1) {
    background-image: url("../../assets/img/slider/mobileImages/G1.jpg");
  }
}


.slide:nth-of-type(2){
  background-image: url("../../assets/img/slider/slider-2.jpg");
  background-size: cover;
  background-position: center;
}

/* @media (max-width: 426px) {
  .slide:nth-of-type(1) {
    background-image: url("../../assets/img/slider/mobileImages/G1.jpg");
  }
} */

.slide:nth-of-type(3){
  background-image: url("../../assets/img/slider/slider-main.jpg");
  background-size: cover;
  background-position: center;
  margin-top: 20px;
}

@media (max-width: 426px) {
  .slide:nth-of-type(3) {
    background-image: url("../../assets/img/slider/mobileImages/N1.jpg");
  }
}

.slide:nth-of-type(4){
  background-image: url("../../assets/img/slider/slider-01.jpg");
  background-size: cover;
  background-position: center;
}

@media (max-width: 426px) {
  .slide:nth-of-type(4) {
    background-image: url("../../assets/img/slider/mobileImages/W1.jpg");
  }
}

/* .slide:nth-of-type(5){
  background-image: url("../../assets/img/slider/slider-02.jpg");
  background-size: cover;
  background-position: center;
} */

.subscribe-thumb {
    background-image: url("../../assets/img/popup/subscribe-bg.jpg");
    background-size: cover;
    background-position: center;
}

/* Responsive styles */
@media (max-width: 1025px) and (min-width: 769px) {
  #slides {
    max-width: 100%;
    height: 400px;
  }
}

@media (max-width: 768px) and (min-width: 426px) {
  #slides {
    max-width: 100%;
    height: 300px;
  }
}

/* @media (max-width: 576px) {
  #slides {
    max-width: 100%;
    height: 200px; 
  }
} */

/* hide Discover Now button for tablet and small laptop */
@media (max-width: 1025px) and (min-width: 426px) {
  .p-btn.p-btn-5 a[href="/Shop"] {
    display: none;
}
}